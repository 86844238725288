import React, {
  useEffect,
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef,
  useMemo,
  useRef,
} from "react";
import { disablePageScroll, enablePageScroll } from "scroll-lock";
import moment from "moment";
import axios from "axios";
import useAlert from "../../store/useAlert";
import useLoading from "../../store/useLoading";
import useLoadingToConnecting from "../../store/useLoadingToConnecting";
import MintButton from "../../components/MintButton/MintButton";
import ScrollDown from "../../components/ScrollDown/ScrollDown";
import VideoArea from "../VideoArea/VideoArea";
import Landing from "../Landing/Landing";
import PlayEntry from "../PlayEntry/PlayEntry";
import About from "../About/About";
import NFT from "../NFT/NFT";
import Mint from "../Mint/Mint";
import Game from "../Game/Game";
import LHC from "../LHC/LHC";
import Roadmap from "../Roadmap/Roadmap";
import AcceptCookie from "../../components/AcceptCookie/AcceptCookie";
import Team from "../Team/Team";
import FQ from "../F&Q/F&Q";
import ReactFullpage from "@fullpage/react-fullpage";
import Footer from "../../components/Footer/Footer";
import { useTranslation } from "react-i18next";
import { GetAddressRequest } from "versionrepo/dist/versionrepo";
import {
  GetFreeMintIndexRequest,
  GetFreeMintClaimedListRequest,
  GetFreeMintUnlockedAmountRequest,
  GetFreeMintClaimedAmountRequest,
  GetFreeMintMaxClaimRequest,
  GetFreeMintPriceRequest,
  GetAllowListIndexRequest,
  GetAllowListClaimedListRequest,
  GetAllowListUnlockedAmountRequest,
  GetAllowListClaimedAmountRequest,
  GetAllowListMaxClaimRequest,
  GetAllowListPriceRequest,
  GetBalanceOfRequest,
  GetTotalSupplyRequest,
  GetOpenSaleUnlockedAmountRequest,
  GetOpenSaleClaimedAmountRequest,
  GetOpenSaleMaxClaimRequest,
  GetOpenSalePriceRequest,
  GetLHCWormholeLHCAddressRequest,
  GetLHCWormholeOwnerShareRateRequest,
  GetLHCWormholeCancelTossFeeRequest,
  GetLHCWormholeExchangeRateRequest,
  PostLHCWormholeTransmuteRequest,
  GetLHCWormholeIsEnoughLHCRequest,
  GetLHCWormholeTossListRequest,
  PostLHCWormholeTossRequest,
  PostLHCApproveRequest,
  PostLHCWormholeCancelTossRequest,
} from "../../request";
import useENV from "../../store/useENV";
import LHCIcon from "./assets/LHC.png";
import BNBIcon from "./assets/BNB.png";
import { numberFormat } from "../../utils/toolFunc";
import cyberLilyImg from "./assets/cyberLily.png";
import BNBChainIcon from "./assets/BNB-chain.svg";
import onScrollHandler from "../../utils/scrollTo";
import sellImage from "./assets/SELL.png";
import buyImage from "./assets/BUY.png";
import buyBtn from "./assets/buyBtn.png";
import buyBtnDisabled from "./assets/LHC-buy-disabled.png";
import sellBtn from "./assets/sellBtn.png";
import sellBtnDisabled from "./assets/LHC-sell-disabled.png";

const WebSide = (
  {
    alert,
    viewportType,
    propStep,
    propPhase,
    gamehallUrl,
    arenaUrl,
    formatAccount,
    setDialogue,
    navAccount,
    dialogue,
    arenaOpened,
  },
  ref
) => {
  const { t } = useTranslation();
  const Web3 = window["Web3"];
  const [fullAccount, setFullAccount] = useState("");
  const [step, setStep] = useState(propStep || "end"); // step 1.comingsoon 2.presale 3.opensale 4.end
  const [phase, setPhase] = useState(
    window.localStorage.getItem("phase") || propPhase || "game"
  ); // phase 1.mint 2.game
  const [freeMintIndexActive, setFreeMintIndexActive] = useState(false); // 合約是否有啟用 Index(白單 - Free Mint)
  const [allowListIndexActive, setAllowListIndexActive] = useState(false); // 合約是否有啟用 Index(白單 - Allow List Mint)
  const [freeMintStage, setFreeMintStage] = useState("inactive"); // Mint Button State 1.inactive 2.active 判斷解鎖數量是否大於 0(白單 - Free Mint)
  const [allowListStage, setAllowListStage] = useState("inactive"); // Mint Button State 1.inactive 2.active 判斷解鎖數量是否大於 0(白單 - Allow List Mint)
  const [stage, setStage] = useState("inactive"); // Mint Button State 1.inactive 2.active 判斷解鎖數量是否大於 0(公售)
  const [hasFreeMintMerkleProof, setHasFreeMintMerkleProof] = useState(false); // 是否有 Proof(白單 - Free Mint)
  const [hasAllowListMerkleProof, setHasAllowListMerkleProof] = useState(false); // 是否有 Proof(白單 - Allow List Mint)
  const [freeMintMaxClaim, setFreeMintMaxClaim] = useState(0); // 拿取單一個人最大可 Mint 數量上限(白單 - Free Mint)
  const [allowListMaxClaim, setAllowListMaxClaim] = useState(0); // 拿取單一個人最大可 Mint 數量上限(白單 - Allow List Mint)
  const [maxClaim, setMaxClaim] = useState(0); // 拿取單一個人最大可 Mint 數量上限(公售)
  const [freeMintWLUnlockAmount, setFreeMintUnlockAmount] = useState(0); // 目前解鎖可 Mint 數量上限(白單 - Free Mint)
  const [allowLIstUnlockAmount, setAllowListUnlockAmount] = useState(0); // 目前解鎖可 Mint 數量上限(白單 - Allow List Mint)
  const [unlockAmount, setUnlockAmount] = useState(0); // 目前解鎖可 Mint 數量上限(公售)
  const [freeMintClaimedAmount, setFreeMintClaimedAmount] = useState(0); // 被 Mint 掉數量(白單 - Free Mint)
  const [allowListClaimedAmount, setAllowListClaimedAmount] = useState(0); // 被 Mint 掉數量(白單 - Allow List Mint)
  const [claimedAmount, setClaimedAmount] = useState(0); // 被 Mint 掉數量(公售)
  const [freeMintPrice, setFreeMintPrice] = useState(); // Mint 價格(白單 - Free Mint)
  const [allowListMintPrice, setAllowListMintPrice] = useState(); // Mint 價格(白單 - Allow List Mint)
  const [mintPrice, setMintPrice] = useState(); // Mint 價格(公售)
  const [totalSupply, setTotalSupply] = useState(0); // 被 Mint 掉總數量(白單 + 公售)
  const [showScroll, setShowScroll] = useState(true);
  const [haveSeenAnnouncement, setHaveSeenAnnouncement] = useState(
    JSON.parse(window.localStorage.getItem("haveSeenAnnouncement")) || false
  );
  const [haveAcceptCookie, setHaveAcceptCookie] = useState(
    Boolean(window.localStorage.getItem("haveAcceptCookie") || false)
  );
  const [walletController, setWalletController] = useState();
  const [loading, setLoading] = useLoading();
  const setLoadingToConnecting = useLoadingToConnecting()[1];
  const [showInventory, setShowInventory] = useState(false); // 要不要顯示 Inventory, 依照使用者是否持有駱馬判斷
  const [inFreeMintClaimedList, setInFreeMintClaimedList] = useState(false); // 用來判斷玩家是否在白單階段 Mint 過了(Free Mint)
  const [inAllowListClaimedList, setInAllowListClaimedList] = useState(false); // 用來判斷玩家是否在白單階段 Mint 過了(Allow List Mint)
  const env = useENV()[0];
  const setAlert = useAlert()[1];
  const [LHCAddress, setLHCAddress] = useState(null);
  const windowScrollY = useRef(window.screenY); // 紀錄開啟彈窗前的視窗頂部位置
  const [buyDialogVisible, setBuyDialogVisible] = useState(false);
  const [sellDialogVisible, setSellDialogVisible] = useState(false);
  const [disclaimerDialogVisible, setDisclaimerDialogVisible] = useState(false);
  const [BNBBalanceOf, setBNBBalanceOf] = useState(0); // 紀錄 BNB 餘額
  const [LHCBalanceOf, setLHCBalanceOf] = useState(0);
  const [exChangeLHCValue, setExChangeLHCValue] = useState(0);
  const [BNBExchangeRate, setBNBExchangeRate] = useState(0);
  const [LHCSellList, setLHCSellList] = useState([]); // 紀錄 LHC Sell List
  const [LHCSellListLoading, setLHCSellListLoading] = useState(true);
  const [LHCOwnerShareRate, setLHCOwnerShareRate] = useState(0); // 紀錄 LHC Owner Share Rate
  const [LHCAddressByWormhole, setLHCAddressByWormhole] = useState(null); // 紀錄 LHC Address By Wormhole
  const [LHCCancelTossFee, setLHCCancelTossFee] = useState(0); // 紀錄 LHC Cancel Toss Fee
  const [LHCSellListSort, setLHCSellListSort] = useState("desc"); // 降序(asc) or 升序(desc)
  const disclaimerRef = useRef();
  const buyDialogRef = useRef();
  const sellDialogRef = useRef();
  const [lhcWormholeContractAddress, setLHCWormholeContractAddress] =
    useState(null); // 紀錄 LHC Wormhole Contract Address

  // 計算匯率
  const calculateLHCExchangeValue = useMemo(() => {
    if (
      Number.isNaN(exChangeLHCValue) ||
      Number.isNaN(BNBExchangeRate) ||
      exChangeLHCValue === 0 ||
      BNBExchangeRate === 0
    )
      return 0;
    return exChangeLHCValue / BNBExchangeRate;
  }, [exChangeLHCValue, BNBExchangeRate]);

  const initBuySellHandler = async () => {
    setDisclaimerDialogVisible(false);
    setExChangeLHCValue(0);
    setBNBExchangeRate(0);
    setBNBBalanceOf(0);
    setLHCBalanceOf(0);
    setLHCSellList([]);
    setLHCOwnerShareRate(0);
    setLHCAddressByWormhole(null);
    setLHCCancelTossFee(0);
    setLHCSellListLoading(true);
  };

  // 初始化
  const initHandler = () => {
    setStep(propStep || "end");
    setPhase(window.localStorage.getItem("phase") || propPhase || "game");
    setFreeMintStage("inactive");
    setAllowListStage("inactive");
    setStage("inactive");
    setHasFreeMintMerkleProof(false);
    setHasAllowListMerkleProof(false);
    setFreeMintMaxClaim(0);
    setAllowListMaxClaim(0);
    setMaxClaim(0);
    setFreeMintUnlockAmount(0);
    setAllowListUnlockAmount(0);
    setUnlockAmount(0);
    setFreeMintClaimedAmount(0);
    setAllowListClaimedAmount(0);
    setClaimedAmount(0);
    setFreeMintPrice();
    setAllowListMintPrice();
    setMintPrice();
    setTotalSupply(0);
    setShowInventory(false);
    setInFreeMintClaimedList(false);
    setInAllowListClaimedList(false);
    setLHCAddress(null);
    setFreeMintIndexActive(false);
    setAllowListIndexActive(false);
    setFullAccount("");
    setBuyDialogVisible(false);
    setSellDialogVisible(false);
    initBuySellHandler();
  };

  const getWormholeInfoHandler = async () => {
    if (!walletController) return;

    const account = await walletController.getAccount();

    walletController.send(
      new GetAddressRequest(
        "Wormhole",
        account,
        async (e) => {
          setLHCWormholeContractAddress(e);
          await initLHCWormholeContract(walletController, e);
        },
        (e) => {
          setLoadingToConnecting(false);
          setLoading(false);
        },
        (e) => {
          setLoadingToConnecting(false);
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    if (!sellDialogVisible && !buyDialogVisible) {
      initBuySellHandler();
      getWormholeInfoHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellDialogVisible, buyDialogVisible]);

  useEffect(() => {
    setStep(propStep);
  }, [propStep]);

  useEffect(() => {
    if (viewportType !== "isMobile") return;
    if (sellDialogVisible || buyDialogVisible) {
      // 修改 body style
      document.body.style.overflow = "hidden";
    } else {
      // 修改 body style
      document.body.style.overflow = "auto";
    }
  }, [sellDialogVisible, buyDialogVisible, viewportType]);

  // 修正彈窗位置，同時禁止彈窗背景可以滑動
  useEffect(() => {
    if (viewportType === "isMobile") return;
    if (sellDialogVisible || buyDialogVisible) {
      // 修改 body style
      window.fullpage_api.setAutoScrolling(false);
      window.fullpage_api.setKeyboardScrolling(false);
      disablePageScroll();
      const scrollY = window.fullpage_api.getScrollY();

      if (disclaimerRef && disclaimerRef.current) {
        disclaimerRef.current.style.top = `${scrollY}px`;
      }

      if (sellDialogVisible && sellDialogRef && sellDialogRef.current) {
        sellDialogRef.current.style.top = `${scrollY}px`;
      }

      if (buyDialogVisible && buyDialogRef && buyDialogRef.current) {
        buyDialogRef.current.style.top = `${scrollY}px`;
      }
    } else {
      window.fullpage_api.setAutoScrolling(true);
      window.fullpage_api.setKeyboardScrolling(true);
      enablePageScroll();
      setDisclaimerDialogVisible(false);
    }
  }, [buyDialogVisible, sellDialogVisible, viewportType]);

  useEffect(() => {
    if (viewportType === "isMobile") return;
    if (alert.show || !haveSeenAnnouncement) {
      window.fullpage_api.setAllowScrolling(false);
      disablePageScroll();
    } else {
      window.fullpage_api.setAllowScrolling(true);
      enablePageScroll();
    }
  }, [alert.show, haveSeenAnnouncement, viewportType]);

  const onLeaveSection = useCallback(
    (origin, destination) => {
      if (destination.anchor === "FOOTER" && showScroll) {
        setShowScroll(false);
      } else if (destination.anchor !== "FOOTER" && !showScroll) {
        setShowScroll(true);
      }

      // setCurrentSection(destination.anchor);
    },
    [showScroll]
  );

  const afterFullpageRender = () => {
    const { hash } = window.location;
    if (hash && hash === "#FOOTER") setShowScroll(false);

    if (hash) {
      const slide = hash.replace("#", "");
      window.fullpage_api.moveTo(slide);
    }
  };

  // 傳遞子元件方法給父元件
  useImperativeHandle(ref, () => ({
    // 初始化合約
    initContract: async (WalletController) => {
      if (!WalletController || !WalletController.wallet) return;
      setWalletController(WalletController);
      const account = await WalletController.getAccount();
      // 初始化所有設定(有可能是換帳號或者登出)
      initHandler();
      setFullAccount(account);
      // 如果錢包沒有帳戶，就不要繼續
      if (!account) {
        setLoadingToConnecting(false);
        setLoading(false);
        return;
      }
      formatAccount(account);
      WalletController.initVersionRepoContract()
        .then(async () => {
          // 拿取 Llama Land 合約地址
          await WalletController.send(
            new GetAddressRequest(
              "LlamaLand",
              account,
              async (e) => {
                await initLlamaLandContract(WalletController, e);
              },
              (e) => {
                setLoadingToConnecting(false);
                setLoading(false);
              },
              (e) => {
                setLoadingToConnecting(false);
                setLoading(false);
              }
            )
          );

          // 如果設定檔沒有 LHC 地址，就直接透過 VersionRepo 拿取 LHC 合約地址
          if (!env || !env.LHC_CURRENCY || !env.LHC_CURRENCY.address) {
            await WalletController.send(
              new GetAddressRequest(
                "LHC",
                account,
                async (e) => setLHCAddress(e),
                (e) => console.error(e),
                (e) => console.error(e)
              )
            );
          }

          // 白單空投階段
          // 檢查是否有 MerkleProof(Free Mint)
          axios
            .get(`${env.API_ROUTER}/api/get-merkle-tree-proof`, {
              params: {
                key: "FREE_MINT",
                account: account,
              },
            })
            .then((res) => {
              const { data: getMerkeTreeProofData, status } = res;
              if (
                status === 200 &&
                getMerkeTreeProofData &&
                getMerkeTreeProofData.proof &&
                getMerkeTreeProofData.proof.length > 0
              ) {
                setHasFreeMintMerkleProof(true);
              } else {
                setHasFreeMintMerkleProof(false);
              }
            });

          // 檢查是否有 MerkleProof(Allow List)
          axios
            .get(`${env.API_ROUTER}/api/get-merkle-tree-proof`, {
              params: {
                key: "ALLOW_LIST",
                account: account,
              },
            })
            .then((res) => {
              const { data: getMerkeTreeProofData, status } = res;
              if (
                status === 200 &&
                getMerkeTreeProofData &&
                getMerkeTreeProofData.proof &&
                getMerkeTreeProofData.proof.length > 0
              ) {
                setHasAllowListMerkleProof(true);
              } else {
                setHasAllowListMerkleProof(false);
              }
            });

          // 拿取 FreeMint 白單合約
          await WalletController.send(
            new GetAddressRequest(
              "LlamaLandFreeMint",
              account,
              async (e) => {
                await initLlamaLandFreeMintContract(WalletController, e);
              },
              (e) => {
                setLoadingToConnecting(false);
                setLoading(false);
              },
              (e) => {
                setLoadingToConnecting(false);
                setLoading(false);
              }
            )
          );

          // 拿取 Allow List Mint 白單合約
          await WalletController.send(
            new GetAddressRequest(
              "LlamaLandAllowList",
              account,
              async (e) => {
                await initLlamaLandAllowListContract(WalletController, e);
              },
              (e) => {
                setLoadingToConnecting(false);
                setLoading(false);
              },
              (e) => {
                setLoadingToConnecting(false);
                setLoading(false);
              }
            )
          );

          // 公售階段
          // 拿取 Llama Land Player 合約地址
          await WalletController.send(
            new GetAddressRequest(
              "LlamaLandPlayer",
              account,
              async (e) => {
                await initLlamaLandPlayerContract(WalletController, e);
              },
              (e) => {
                setLoadingToConnecting(false);
                setLoading(false);
              },
              (e) => {
                setLoadingToConnecting(false);
                setLoading(false);
              }
            )
          );

          // 拿取 LHC 合約地址
          await WalletController.send(
            new GetAddressRequest(
              "LHC",
              account,
              async (e) => {
                await initLHCContract(WalletController, e);
              },
              (e) => {
                setLoadingToConnecting(false);
                setLoading(false);
              },
              (e) => {
                setLoadingToConnecting(false);
                setLoading(false);
              }
            )
          );

          // 拿取 LHC Wormhole 合約地址
          await WalletController.send(
            new GetAddressRequest(
              "Wormhole",
              account,
              async (e) => {
                setLHCWormholeContractAddress(e);
                await initLHCWormholeContract(WalletController, e);
              },
              (e) => {
                setLoadingToConnecting(false);
                setLoading(false);
              },
              (e) => {
                setLoadingToConnecting(false);
                setLoading(false);
              }
            )
          );
        })
        .catch((err) => {
          console.error("init version repo contract error", err);
        });
    },
  }));

  // 初始化 LlamaLand 合約
  const initLlamaLandContract = async (
    WalletController,
    llamaLandContractAddress
  ) => {
    await WalletController.initLlamaLandContract(llamaLandContractAddress);

    const account = await WalletController.getAccount();

    // 取得使用者有幾個駱馬, 判斷要不要顯示 Market
    await WalletController.send(
      new GetBalanceOfRequest(
        account,
        (e) => {
          if (Number(e) > 0) {
            setShowInventory(true);
          } else {
            setShowInventory(false);
          }
        },
        (e) => console.error("onReject", e),
        (e) => console.error("onError", e)
      )
    );

    // 拿取已經被 Mint 掉的數量
    await WalletController.send(
      new GetTotalSupplyRequest(
        account,
        (e) => setTotalSupply(e),
        (e) => console.error("onReject", e),
        (e) => console.error("onError", e)
      )
    );
  };

  // 初始化 LlamaLandFreeMint 合約
  const initLlamaLandFreeMintContract = async (
    WalletController,
    llamaLandFreeMintContractAddress
  ) => {
    await WalletController.initLlamaLandFreeMintContract(
      llamaLandFreeMintContractAddress
    );

    const account = await WalletController.getAccount();

    // 先查看該合約的 Index 是否大於 0，如果大於 0 代表合約可以使用
    await WalletController.send(
      new GetFreeMintIndexRequest(
        account,
        async (index) => {
          if (Number(index) !== 0) {
            setFreeMintIndexActive(true);

            // 查看玩家是否有 Mint 過了
            await WalletController.send(
              new GetFreeMintClaimedListRequest(
                account,
                (claimedIndex) => {
                  // 如果玩家有 Mint 過，就把按鈕設定為 disabled，判斷為 get contract 的 index 需要大於等於 claimedIndex
                  if (claimedIndex >= index) {
                    setInFreeMintClaimedList(true);
                  } else {
                    setInFreeMintClaimedList(false);
                  }
                },
                (e) => console.error("onReject", e),
                (e) => console.error("onError", e)
              )
            );
          } else {
            setFreeMintIndexActive(false);
          }
        },
        (e) => console.error("onReject", e),
        (e) => console.error("onError", e)
      )
    );

    // 拿取目前解鎖可 Mint 數量上限
    await WalletController.send(
      new GetFreeMintUnlockedAmountRequest(
        account,
        (e) => {
          setFreeMintUnlockAmount(e);
          if (e > 0) {
            setFreeMintStage("active");
          } else {
            setFreeMintStage("inactive");
          }
        },
        (e) => console.error("onReject", e),
        (e) => console.error("onError", e)
      )
    );

    // 拿取 WL 已經被 Mint 掉的數量
    await WalletController.send(
      new GetFreeMintClaimedAmountRequest(
        account,
        (e) => setFreeMintClaimedAmount(e),
        (e) => console.error("onReject", e),
        (e) => console.error("onError", e)
      )
    );

    // 拿取 WL 單一個人最大可 Mint 數量上限
    await WalletController.send(
      new GetFreeMintMaxClaimRequest(
        account,
        (e) => setFreeMintMaxClaim(e),
        (e) => console.error("onReject", e),
        (e) => console.error("onError", e)
      )
    );

    // 拿取 WL Mint Price
    await WalletController.send(
      new GetFreeMintPriceRequest(
        account,
        (e) => setFreeMintPrice(Web3.utils.fromWei(e)),
        (e) => console.error("onReject", e),
        (e) => console.error("onError", e)
      )
    );

    setLoadingToConnecting(false);
    setLoading(false);
  };

  // 初始化 LlamaLandAllowList 合約
  const initLlamaLandAllowListContract = async (
    WalletController,
    llamaLandAllowListContractAddress
  ) => {
    await WalletController.initLlamaLandAllowListContract(
      llamaLandAllowListContractAddress
    );

    const account = await WalletController.getAccount();

    // 先查看該合約的 Index 是否大於 0，如果大於 0 代表合約可以使用
    await WalletController.send(
      new GetAllowListIndexRequest(
        account,
        async (index) => {
          if (Number(index) !== 0) {
            setAllowListIndexActive(true);

            // 查看玩家是否有 Mint 過了
            await WalletController.send(
              new GetAllowListClaimedListRequest(
                account,
                (claimedIndex) => {
                  // 如果玩家有 Mint 過，就把按鈕設定為 disabled，判斷為 get contract 的 index 需要大於等於 claimedIndex
                  if (claimedIndex >= index) {
                    setInAllowListClaimedList(true);
                  } else {
                    setInAllowListClaimedList(false);
                  }
                },
                (e) => console.error("onReject", e),
                (e) => console.error("onError", e)
              )
            );
          } else {
            setAllowListIndexActive(false);
          }
        },
        (e) => console.error("onReject", e),
        (e) => console.error("onError", e)
      )
    );

    // 拿取目前解鎖可 Mint 數量上限
    await WalletController.send(
      new GetAllowListUnlockedAmountRequest(
        account,
        (e) => {
          setAllowListUnlockAmount(e);
          if (e > 0) {
            setAllowListStage("active");
          } else {
            setAllowListStage("inactive");
          }
        },
        (e) => console.error("onReject", e),
        (e) => console.error("onError", e)
      )
    );

    // 拿取 WL 已經被 Mint 掉的數量
    await WalletController.send(
      new GetAllowListClaimedAmountRequest(
        account,
        (e) => setAllowListClaimedAmount(e),
        (e) => console.error("onReject", e),
        (e) => console.error("onError", e)
      )
    );

    // 拿取 WL 單一個人最大可 Mint 數量上限
    await WalletController.send(
      new GetAllowListMaxClaimRequest(
        account,
        (e) => setAllowListMaxClaim(e),
        (e) => console.error("onReject", e),
        (e) => console.error("onError", e)
      )
    );

    // 拿取 WL Mint Price
    await WalletController.send(
      new GetAllowListPriceRequest(
        account,
        (e) => setAllowListMintPrice(Web3.utils.fromWei(e)),
        (e) => console.error("onReject", e),
        (e) => console.error("onError", e)
      )
    );

    setLoadingToConnecting(false);
    setLoading(false);
  };

  // 初始化 LlamaLandPlayer 合約
  const initLlamaLandPlayerContract = async (
    WalletController,
    llamaLandPlayerContractAddress
  ) => {
    await WalletController.initLlamaLandPlayerContract(
      llamaLandPlayerContractAddress
    );

    const account = await WalletController.getAccount();

    // 拿取目前解鎖可 Mint 數量上限
    await WalletController.send(
      new GetOpenSaleUnlockedAmountRequest(
        account,
        (e) => {
          setUnlockAmount(e);
          if (e > 0) {
            setStage("active");
          } else {
            setStage("inactive");
          }
        },
        (e) => console.error("onReject", e),
        (e) => console.error("onError", e)
      )
    );

    // 拿取已經被 Mint 掉的數量
    await WalletController.send(
      new GetOpenSaleClaimedAmountRequest(
        account,
        (e) => setClaimedAmount(e),
        (e) => console.error("onReject", e),
        (e) => console.error("onError", e)
      )
    );

    // 拿取單一個人最大可 Mint 數量上限
    await WalletController.send(
      new GetOpenSaleMaxClaimRequest(
        account,
        (e) => setMaxClaim(e),
        (e) => console.error("onReject", e),
        (e) => console.error("onError", e)
      )
    );

    // 拿取 Mint Price
    await WalletController.send(
      new GetOpenSalePriceRequest(
        account,
        (e) => setMintPrice(Web3.utils.fromWei(e)),
        (e) => console.error("onReject", e),
        (e) => console.error("onError", e)
      )
    );

    setLoadingToConnecting(false);
    setLoading(false);
  };

  // 初始化 LHC 合約
  const initLHCContract = async (WalletController, lhcContractAddress) => {
    await WalletController.initLHCContract(lhcContractAddress);
  };

  // 初始化 LHC Wormhole 合約
  const initLHCWormholeContract = async (
    WalletController,
    propLhcWormholeContractAddress
  ) => {
    await WalletController.initLHCWormholeContract(
      propLhcWormholeContractAddress
    );

    const account = await WalletController.getAccount();

    // 拿取 LHC Address
    await WalletController.send(
      new GetLHCWormholeLHCAddressRequest(
        account,
        (e) => {
          setLHCAddressByWormhole(e);
        },
        (e) => console.error("onReject", e),
        (e) => console.error("onError", e)
      )
    );

    // 拿取 Owner share Rate
    await WalletController.send(
      new GetLHCWormholeOwnerShareRateRequest(
        account,
        (e) => setLHCOwnerShareRate(Number.isNaN(e) ? 0 : 100 / Number(e)),
        (e) => console.error("onReject", e),
        (e) => console.error("onError", e)
      )
    );

    // 拿取 Exchange Rate
    await WalletController.send(
      new GetLHCWormholeExchangeRateRequest(
        account,
        (e) => setBNBExchangeRate(Number.isNaN(e) ? 0 : Number(e)),
        (e) => console.error("onReject", e),
        (e) => console.error("onError", e)
      )
    );

    // 拿取 Cancel Toss Fee
    await WalletController.send(
      new GetLHCWormholeCancelTossFeeRequest(
        account,
        (e) => setLHCCancelTossFee(Web3.utils.fromWei(e)),
        (e) => console.error("onReject", e),
        (e) => console.error("onError", e)
      )
    );

    setLoadingToConnecting(false);
    setLoading(false);
  };

  // 更新合約資訊
  const updateContract = async () => {
    if (step !== "presale" && step !== "opensale") return;
    setLoading(true);

    const account = await walletController.getAccount();

    // 檢查是否有 MerkleProof(Free Mint)
    axios
      .get(`${env.API_ROUTER}/api/get-merkle-tree-proof`, {
        params: {
          key: "FREE_MINT",
          account: account,
        },
      })
      .then((res) => {
        const { data: getMerkeTreeProofData, status } = res;
        if (
          status === 200 &&
          getMerkeTreeProofData &&
          getMerkeTreeProofData.proof &&
          getMerkeTreeProofData.proof.length > 0
        ) {
          setHasFreeMintMerkleProof(true);
        } else {
          setHasFreeMintMerkleProof(false);
        }
      });

    // 檢查是否有 MerkleProof(Allow List)
    axios
      .get(`${env.API_ROUTER}/api/get-merkle-tree-proof`, {
        params: {
          key: "ALLOW_LIST",
          account: account,
        },
      })
      .then((res) => {
        const { data: getMerkeTreeProofData, status } = res;
        if (
          status === 200 &&
          getMerkeTreeProofData &&
          getMerkeTreeProofData.proof &&
          getMerkeTreeProofData.proof.length > 0
        ) {
          setHasAllowListMerkleProof(true);
        } else {
          setHasAllowListMerkleProof(false);
        }
      });

    // 拿取 Llama Land 合約地址
    await walletController.send(
      new GetAddressRequest(
        "LlamaLand",
        account,
        async (e) => {
          await initLlamaLandContract(walletController, e);
        },
        (e) => {
          setLoadingToConnecting(false);
          setLoading(false);
        },
        (e) => {
          setLoadingToConnecting(false);
          setLoading(false);
        }
      )
    );

    // 拿取 Llama Land Free Mint 及 Allow List 合約地址
    // 拿取 FreeMint 白單合約
    await walletController.send(
      new GetAddressRequest(
        "LlamaLandFreeMint",
        account,
        async (e) => {
          await initLlamaLandFreeMintContract(walletController, e);
        },
        (e) => {
          setLoadingToConnecting(false);
          setLoading(false);
        },
        (e) => {
          setLoadingToConnecting(false);
          setLoading(false);
        }
      )
    );

    // 拿取 Allow List Mint 白單合約
    await walletController.send(
      new GetAddressRequest(
        "LlamaLandAllowList",
        account,
        async (e) => {
          await initLlamaLandAllowListContract(walletController, e);
        },
        (e) => {
          setLoadingToConnecting(false);
          setLoading(false);
        },
        (e) => {
          setLoadingToConnecting(false);
          setLoading(false);
        }
      )
    );

    // 拿取 Llama Land Player 合約地址
    await walletController.send(
      new GetAddressRequest(
        "LlamaLandPlayer",
        account,
        async (e) => {
          await initLlamaLandPlayerContract(walletController, e);
        },
        (e) => {
          setLoadingToConnecting(false);
          setLoading(false);
        },
        (e) => {
          setLoadingToConnecting(false);
          setLoading(false);
        }
      )
    );

    // 拿取 LHC 合約地址
    await walletController.send(
      new GetAddressRequest(
        "LHC",
        account,
        async (e) => {
          await initLHCContract(walletController, e);
        },
        (e) => {
          setLoadingToConnecting(false);
          setLoading(false);
        },
        (e) => {
          setLoadingToConnecting(false);
          setLoading(false);
        }
      )
    );

    // 拿取 LHC Wormhole 合約地址
    await walletController.send(
      new GetAddressRequest(
        "Wormhole",
        account,
        async (e) => {
          await initLHCWormholeContract(walletController, e);
        },
        (e) => {
          setLoadingToConnecting(false);
          setLoading(false);
        },
        (e) => {
          setLoadingToConnecting(false);
          setLoading(false);
        }
      )
    );
  };

  const viewportTypeMemo = useMemo(() => {
    let fullView =
      propPhase === "game"
        ? [
            "LANDING",
            "VIDEO",
            "PLAYENTRY",
            "ABOUT",
            "NFT",
            "MINT",
            "GAME",
            "LHC",
            "ROADMAP",
            "TEAM",
            "FQ",
            "FOOTER",
          ]
        : [
            "LANDING",
            "VIDEO",
            "ABOUT",
            "NFT",
            "MINT",
            "GAME",
            "LHC",
            "ROADMAP",
            "TEAM",
            "FQ",
            "FOOTER",
          ];

    return fullView;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewportType, propPhase, phase]);

  useEffect(() => {
    if (!window.fullpage_api || viewportType !== "isMobile") return;
    // 如果有開啟彈窗，先紀錄開啟前的位置
    if (sellDialogVisible || buyDialogVisible) {
      // 記錄滑動位置
      windowScrollY.current = window.scrollY;

      if (viewportType === "isMobile") {
        // 視窗移動到最上方
        window.scrollTo({
          top: 0,
        });
      }
    } else {
      if (viewportType === "isMobile") {
        // 視窗移動到原本位置
        window.scrollTo({
          top: windowScrollY.current,
        });
      } else {
        onScrollHandler(viewportType, "LHC");
      }
    }
  }, [sellDialogVisible, buyDialogVisible, viewportType]);

  const onErrorShow = useCallback((e, callback = () => {}) => {
    setAlert({
      show: true,
      type: "failure",
      title: "Oops!",
      message: e,
      callback() {
        callback();
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 更新 Order List
  const reloadOrderListHandler = async () => {
    if (!walletController || !walletController.wallet || !navAccount) return;

    if (!LHCSellListLoading) {
      setLHCSellListLoading(true);

      const account = await walletController.getAccount();

      // 拿到賣單列表
      await walletController.send(
        new GetLHCWormholeTossListRequest(
          account,
          (e) => {
            setLHCSellList(
              e.map((tossInfo) => {
                const { addr, timestamp, lhcTotal, lhcAmount } = tossInfo;

                return {
                  id: addr + timestamp,
                  addr,
                  timestamp: moment(timestamp * 1000)
                    .utc()
                    .format("YYYY-MM-DD HH:mm:ss"),
                  originalTimestamp: timestamp,
                  lhcTotal: Web3.utils.fromWei(lhcTotal),
                  lhcAmount: Web3.utils.fromWei(lhcAmount),
                };
              })
            );
            setLHCSellListLoading(false);
            setSellDialogVisible(true);
          },
          (e) => console.error("onReject", e),
          (e) => console.error("onError", e)
        )
      );
    }
  };

  /* 加上 className 為了透過 CSS 隱藏 Nav 組件的置頂 */
  return (
    <div className={!haveSeenAnnouncement ? "notSeenVideo" : ""}>
      <ReactFullpage
        licenseKey={"7C2262BC-E6044700-BA605146-B340179C"}
        menu="#Navs"
        anchors={viewportTypeMemo}
        navigation={viewportType !== "isMobile" && dialogue === "" && !loading}
        navigationTooltips={viewportTypeMemo.map((viewportTypeText) => {
          switch (viewportTypeText) {
            case "LANDING":
              return "LLAMA LAND";
            case "PLAYENTRY":
              return "PLAY";
            case "LHC":
              return "$LHC";
            case "FQ":
              return "F&Q";
            case "FOOTER":
              return "CONTACT";
            default:
              return viewportTypeText;
          }
        })}
        easingcss3="cubic-bezier(0.68, 0, 0.265, 1)"
        scrollingSpeed={1000}
        onLeave={onLeaveSection}
        afterRender={afterFullpageRender}
        render={({ state, fullpageApi }) => {
          if (state.initialized) {
            if (
              viewportType === "isDesktopNarrow" ||
              viewportType === "isMobile"
            ) {
              fullpageApi.setResponsive(true);
            } else {
              fullpageApi.setResponsive(false);
            }
          }

          return (
            <ReactFullpage.Wrapper className="hasModal">
              <Landing
                viewportType={viewportType}
                haveSeenAnnouncement={haveSeenAnnouncement}
                setHaveSeenAnnouncement={setHaveSeenAnnouncement}
              />
              <VideoArea />
              {phase === "game" && (
                <PlayEntry
                  show={true}
                  gamehallUrl={gamehallUrl}
                  arenaUrl={arenaUrl}
                  viewportType={viewportType}
                  setDialogue={setDialogue}
                  arenaOpened={arenaOpened}
                />
              )}
              <About />
              <NFT step={step} mintPrice={mintPrice} />
              <Mint
                step={step}
                freeMintStage={freeMintStage}
                allowListStage={allowListStage}
                stage={stage}
                totalSupply={totalSupply}
                freeMintMaxClaim={freeMintMaxClaim}
                allowListMaxClaim={allowListMaxClaim}
                maxClaim={maxClaim}
                freeMintPrice={freeMintPrice}
                allowListMintPrice={allowListMintPrice}
                mintPrice={mintPrice}
                freeMintUnlockAmount={freeMintWLUnlockAmount}
                allowListUnlockAmount={allowLIstUnlockAmount}
                unlockAmount={unlockAmount}
                freeMintClaimedAmount={freeMintClaimedAmount}
                allowListClaimedAmount={allowListClaimedAmount}
                claimedAmount={claimedAmount}
                WalletController={walletController}
                showInventory={showInventory}
                inFreeMintClaimedList={inFreeMintClaimedList}
                inAllowListClaimedList={inAllowListClaimedList}
                hasFreeMintMerkleProof={hasFreeMintMerkleProof}
                hasAllowListMerkleProof={hasAllowListMerkleProof}
                updateContract={updateContract}
                env={env}
                navAccount={navAccount}
                setDialogue={setDialogue}
                freeMintIndexActive={freeMintIndexActive}
                allowListIndexActive={allowListIndexActive}
              />
              <Game />
              <LHC
                PropsLHCAddress={LHCAddress}
                setDialogue={setDialogue}
                navAccount={navAccount}
                env={env}
                viewportType={viewportType}
                setBuyDialogVisible={setBuyDialogVisible}
                setSellDialogVisible={setSellDialogVisible}
                setBNBBalanceOf={setBNBBalanceOf}
                setLHCBalanceOf={setLHCBalanceOf}
                setLHCSellList={setLHCSellList}
                setLHCSellListLoading={setLHCSellListLoading}
                WalletController={walletController}
                LHCAddressByWormhole={LHCAddressByWormhole}
              />
              <Roadmap viewportType={viewportType} />
              <Team viewportType={viewportType} />
              <FQ />
              <Footer viewportType={viewportType} env={env} />
            </ReactFullpage.Wrapper>
          );
        }}
      />

      <MintButton
        viewportType={viewportType}
        allowListStage={allowListStage}
        freeMintStage={freeMintStage}
        stage={stage}
        step={step}
        totalSupply={totalSupply}
        freeMintUnlockAmount={freeMintWLUnlockAmount}
        allowListUnlockAmount={allowLIstUnlockAmount}
        unlockAmount={unlockAmount}
        freeMintClaimedAmount={freeMintClaimedAmount}
        allowListClaimedAmount={allowListClaimedAmount}
        claimedAmount={claimedAmount}
        showInventory={showInventory}
        inFreeMintClaimedList={inFreeMintClaimedList}
        inAllowListClaimedList={inAllowListClaimedList}
        hasFreeMintMerkleProof={hasFreeMintMerkleProof}
        hasAllowListMerkleProof={hasAllowListMerkleProof}
        env={env}
        navAccount={navAccount}
        setDialogue={setDialogue}
        gameStep={phase === "game"}
        freeMintIndexActive={freeMintIndexActive}
        allowListIndexActive={allowListIndexActive}
      />

      <ScrollDown show={showScroll} />

      {/* disclaimer Modal */}
      <div
        ref={disclaimerRef}
        className={
          disclaimerDialogVisible
            ? "dialog dialog-disclaimer dialog-show"
            : "dialog dialog-disclaimer"
        }
      >
        {/* blur */}
        <div
          className="dialog-blur"
          onClick={() => setDisclaimerDialogVisible(false)}
        />
        <div className="dialog-container dialog-container-disclaimer">
          <div className="dialog-buttons">
            <span className="dialog-button dialog-buttons-close fa-2x">
              <i
                className="fa-sharp fa-regular fa-circle-xmark"
                onClick={() => setDisclaimerDialogVisible(false)}
              ></i>
            </span>
          </div>
          {/* Mint Dialog Content */}
          <div className="dialog-content">
            <span className="title">{t("Disclaimer")}</span>
            <ul className="itemList">
              <li className="itemList-item">{t("DisclaimerOne")}</li>
              <li className="itemList-item">{t("DisclaimerTwo")}</li>
              <li className="itemList-item">{t("DisclaimerThree")}</li>
              <li className="itemList-item">{t("DisclaimerFour")}</li>
              <li className="itemList-item">{t("DisclaimerFive")}</li>
            </ul>
            <p>{t("DisclaimerPS")}</p>
          </div>
        </div>
      </div>

      {/* Buy LHC Modal */}
      <div
        ref={buyDialogRef}
        className={buyDialogVisible ? "dialog dialog-show" : "dialog"}
      >
        {/* blur */}
        <div
          className="dialog-blur"
          onClick={() => setBuyDialogVisible(false)}
        />
        <div className="dialog-container dialog-container-buy">
          <div className="dialog-userInfo">
            <img
              className="BNB-chain-icon"
              src={BNBChainIcon}
              alt="BNB Chain Icon"
            />
            <span className="address">
              {fullAccount && fullAccount !== ""
                ? `${fullAccount.slice(0, 6)}...${fullAccount.slice(
                    -6,
                    fullAccount.length
                  )}`
                : "-"}
            </span>
          </div>
          <div className="dialog-buttons">
            <span className="dialog-button dialog-buttons-disclaimer fa-2x">
              <a
                href={
                  env &&
                  env.BLOCK_EXPLORER_URLS &&
                  lhcWormholeContractAddress &&
                  fullAccount
                    ? `${env.BLOCK_EXPLORER_URLS}/address/${lhcWormholeContractAddress}?fromaddress=${fullAccount}`
                    : "#"
                }
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-solid fa-list"></i>
              </a>
            </span>
            <span className="dialog-button dialog-buttons-disclaimer fa-2x">
              <i
                className="fa-sharp fa-solid fa-circle-info"
                onClick={() => setDisclaimerDialogVisible(true)}
              ></i>
            </span>
            <span className="dialog-button dialog-buttons-close fa-2x">
              <i
                className="fa-sharp fa-regular fa-circle-xmark"
                onClick={() => setBuyDialogVisible(false)}
              ></i>
            </span>
          </div>
          {/* Mint Dialog Content */}
          <div className="dialog-content">
            <div className="banner banner-buy">
              <img className="banner-image" src={buyImage} alt="Buy LHC" />
            </div>
            <div className="setting-area">
              <div>
                <div>
                  <p className="iconTitle">
                    <img
                      className="iconTitle-img"
                      src={BNBIcon}
                      alt="BNB icon"
                    />
                    BNB
                  </p>
                  <span className="BNBExchangeValue">
                    <span className="inputArea-remaining">
                      {calculateLHCExchangeValue.toString()}
                      <small className="inputArea-remaining-text">
                        <span className="remainingSlash">/</span>
                        <span className="remainingNumber">
                          {numberFormat({
                            number: BNBBalanceOf,
                            decimalPoint: 3,
                          })}
                        </span>
                      </small>
                    </span>
                  </span>
                </div>
              </div>
              <div className="arrow-icon">
                <span className="arrowArea">
                  <i className="fa-solid fa-arrow-right"></i>
                </span>
              </div>
              <div>
                <div>
                  <div className="iconTitle">
                    <img
                      className="iconTitle-img"
                      src={LHCIcon}
                      alt="LHC icon"
                    />
                    LHC
                  </div>
                  <div className="inputArea">
                    <input
                      type="number"
                      value={exChangeLHCValue.toString()}
                      className="inputArea-number"
                      onKeyPress={(e) => {
                        // 阻擋小數點及e
                        const charCode = e.charCode || e.keyCode;

                        if (charCode === 101 || charCode === 46) {
                          e.preventDefault();
                        }
                      }}
                      onBlur={() => {
                        if (viewportType !== "isMobile") return;
                        // 畫面移動到最上方
                        window.scrollTo({
                          top: 0,
                        });
                      }}
                      onChange={(e) => {
                        const value = e.target.value;
                        // 只允許輸入整數
                        if (/^[0-9]+$/.test(value)) {
                          // 符合要求，更新輸入值
                          setExChangeLHCValue(Number(value));
                        } else {
                          setExChangeLHCValue(0);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="confirm-area">
              {exChangeLHCValue <= 0 ? (
                <img
                  className="confirm-area-image confirm-area-image-disabled"
                  src={buyBtnDisabled}
                  alt="Buy LHC Disabled"
                />
              ) : (
                <img
                  className="confirm-area-image"
                  src={buyBtn}
                  alt="Buy LHC"
                  onClick={async () => {
                    if (
                      !walletController ||
                      !walletController.wallet ||
                      !navAccount
                    )
                      return;
                    if (exChangeLHCValue > 0) {
                      const account = await walletController.getAccount();

                      if (!account) {
                        setAlert({
                          show: true,
                          type: "error",
                          title: "Error",
                          message: "Please connect wallet.",
                        });
                        return;
                      }

                      // 查看現在是否有足夠的 LHC
                      await walletController.send(
                        new GetLHCWormholeIsEnoughLHCRequest(
                          account,
                          calculateLHCExchangeValue,
                          (e) => {
                            if (!e) {
                              onErrorShow(t("LHCSection.Not enough LHC"));
                              return;
                            }

                            if (calculateLHCExchangeValue > BNBBalanceOf) {
                              onErrorShow(t("LHCSection.Not enough BNB"));
                              return;
                            }

                            setLoading(true);

                            walletController.send(
                              new PostLHCWormholeTransmuteRequest(
                                calculateLHCExchangeValue,
                                account,
                                (e) => {
                                  setLoading(false);
                                  setAlert({
                                    show: true,
                                    type: "info",
                                    title: t(
                                      "LHCSection.Waiting for transition"
                                    ),
                                    message: t(
                                      "LHCSection.Please do not refresh this page"
                                    ),
                                  });
                                },
                                (e) => {
                                  setLoading(false);
                                  onErrorShow(e.toString() ?? "onReject");
                                },
                                (e) => {
                                  setLoading(false);
                                  onErrorShow(e.toString() ?? "onError");
                                },
                                (e) => {
                                  setLoading(false);
                                  if (e && e.status) {
                                    setAlert({
                                      show: true,
                                      type: "success",
                                      title: t("LHCSection.Success"),
                                      message: t("LHCSection.Buy LHC Success"),
                                      callback: () =>
                                        setBuyDialogVisible(false),
                                    });
                                  } else {
                                    onErrorShow(
                                      t(
                                        "LHCSection.Buy LHC Fails, please try again"
                                      )
                                    );
                                  }
                                },
                                (e) => {
                                  setLoading(false);

                                  const errorResponse = e.message;
                                  const startIndex =
                                    errorResponse.indexOf('message":"') +
                                    'message":"'.length;
                                  const endIndex = errorResponse.indexOf(
                                    '",',
                                    startIndex
                                  );
                                  const errorReason = errorResponse.substring(
                                    startIndex,
                                    endIndex
                                  );

                                  onErrorShow(
                                    errorReason ||
                                      t(
                                        "LHCSection.Buy LHC Fails, please try again"
                                      ),
                                    () => {
                                      setBuyDialogVisible(false);
                                    }
                                  );
                                }
                              )
                            );
                          },
                          (e) => console.error("onReject", e),
                          (e) => console.error("onError", e)
                        )
                      );
                    }
                  }}
                />
              )}
              {/* <button
                className="confirm-area-btn confirm-area-btn-buy"
                disabled={exChangeLHCValue <= 0}
              >Buy</button> */}
            </div>
          </div>
        </div>
      </div>

      {/* Sell LHC Modal */}
      <div
        ref={sellDialogRef}
        className={sellDialogVisible ? "dialog dialog-show" : "dialog"}
      >
        {/* blur */}
        <div
          className="dialog-blur"
          onClick={() => setSellDialogVisible(false)}
        />
        <div className="dialog-container dialog-container-sell">
          <div className="dialog-userInfo">
            <img
              className="BNB-chain-icon"
              src={BNBChainIcon}
              alt="BNB Chain Icon"
            />
            <span className="address">
              {fullAccount && fullAccount !== ""
                ? `${fullAccount.slice(0, 6)}...${fullAccount.slice(
                    -6,
                    fullAccount.length
                  )}`
                : "-"}
            </span>
          </div>
          <div className="dialog-buttons">
            <span className="dialog-button dialog-buttons-disclaimer fa-2x">
              <a
                href={
                  env &&
                  env.BLOCK_EXPLORER_URLS &&
                  lhcWormholeContractAddress &&
                  fullAccount
                    ? `${env.BLOCK_EXPLORER_URLS}/address/${lhcWormholeContractAddress}?fromaddress=${fullAccount}`
                    : "#"
                }
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-solid fa-list"></i>
              </a>
            </span>
            <span className="dialog-button dialog-buttons-disclaimer fa-2x">
              <i
                className="fa-sharp fa-solid fa-circle-info"
                onClick={() => setDisclaimerDialogVisible(true)}
              ></i>
            </span>
            <span className="dialog-button dialog-buttons-close fa-2x">
              <i
                className="fa-sharp fa-regular fa-circle-xmark"
                onClick={() => setSellDialogVisible(false)}
              ></i>
            </span>
          </div>
          {/* Mint Dialog Content */}
          <div className="dialog-content">
            <div className="banner banner-sell">
              <img className="banner-image" src={sellImage} alt="Sell LHC" />
            </div>
            <div className="setting-area">
              <div>
                <div>
                  <div className="iconTitle">
                    <img
                      className="iconTitle-img"
                      src={LHCIcon}
                      alt="LHC icon"
                    />
                    LHC
                  </div>
                  <div className="inputArea inputArea-sell">
                    <input
                      type="number"
                      value={exChangeLHCValue.toString()}
                      className="inputArea-number"
                      onKeyPress={(e) => {
                        // 阻擋小數點及e
                        const charCode = e.charCode || e.keyCode;

                        if (charCode === 101 || charCode === 46) {
                          e.preventDefault();
                        }
                      }}
                      onBlur={() => {
                        if (viewportType !== "isMobile") return;
                        // 畫面移動到最上方，解決鍵盤收起後畫面不會回到原本位置
                        window.scrollTo({
                          top: 0,
                        });
                      }}
                      onChange={(e) => {
                        const value = e.target.value;
                        // 只允許輸入整數
                        if (/^[0-9]+$/.test(value)) {
                          // 檢查是否大於餘額
                          if (Number(value) > LHCBalanceOf) {
                            setExChangeLHCValue(LHCBalanceOf);
                            return;
                          }
                          // 符合要求，更新輸入值
                          setExChangeLHCValue(Number(value));
                        } else {
                          setExChangeLHCValue(0);
                        }
                      }}
                    />
                    <div className="inputArea-remaining">
                      <div className="inputArea-remaining-text">
                        <span className="remainingSlash">/</span>
                        <span className="remainingNumber">
                          {numberFormat({
                            number: LHCBalanceOf,
                            decimalPoint: 0,
                          })}
                        </span>
                      </div>
                      <button
                        className="inputArea-remaining-maxBtn"
                        onClick={() => setExChangeLHCValue(LHCBalanceOf)}
                      >
                        {t("WebSide.Max")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="arrow-icon">
                <span className="arrowArea">
                  <i className="fa-solid fa-arrow-right"></i>
                </span>
              </div>
              <div>
                <div>
                  <p className="iconTitle">
                    <img
                      className="iconTitle-img"
                      src={BNBIcon}
                      alt="BNB icon"
                    />
                    BNB
                  </p>
                  <p className="BNBExchangeValue">
                    {calculateLHCExchangeValue.toString()}
                  </p>
                  <small className="remindText">
                    {t("WebSide.You will be charge transition fee", {
                      fee: LHCOwnerShareRate,
                    })}
                  </small>
                </div>
              </div>
            </div>
            <div className="confirm-area">
              {exChangeLHCValue <= 0 || LHCBalanceOf <= 0 ? (
                <img
                  className="confirm-area-image confirm-area-image-disabled"
                  src={sellBtnDisabled}
                  alt="Sell LHC Disabled"
                />
              ) : (
                <img
                  className="confirm-area-image"
                  src={sellBtn}
                  alt="Sell LHC"
                  onClick={async () => {
                    if (
                      !walletController ||
                      !walletController.wallet ||
                      !navAccount
                    )
                      return;
                    if (exChangeLHCValue > 0) {
                      const account = await walletController.getAccount();

                      if (!account) return;

                      setLoading(true);

                      // 授權 LHC 轉移
                      walletController.send(
                        new PostLHCApproveRequest(
                          walletController.lhcContractAddress,
                          exChangeLHCValue,
                          account,
                          (e) => {
                            setLoading(false);
                            setAlert({
                              show: true,
                              type: "info",
                              title: t("LHCSection.Waiting for transition"),
                              message: t(
                                "LHCSection.Please do not refresh this page"
                              ),
                            });
                          },
                          (e) => {
                            setLoading(false);
                            onErrorShow(e.toString() ?? "onReject");
                          },
                          (e) => {
                            setLoading(false);
                            onErrorShow(e.toString() ?? "onError");
                          },
                          (e) => {
                            setLoading(false);
                            if (e && e.status) {
                              // 轉移 LHC
                              walletController.send(
                                new PostLHCWormholeTossRequest(
                                  exChangeLHCValue,
                                  account,
                                  (e) => {
                                    setLoading(false);
                                    setAlert({
                                      show: true,
                                      type: "info",
                                      title: t(
                                        "LHCSection.Waiting for transition"
                                      ),
                                      message: t(
                                        "LHCSection.Please do not refresh this page"
                                      ),
                                    });
                                  },
                                  (e) => {
                                    setLoading(false);
                                    onErrorShow(e.toString() ?? "onReject");
                                  },
                                  (e) => {
                                    setLoading(false);
                                    onErrorShow(e.toString() ?? "onError");
                                  },
                                  (e) => {
                                    setLoading(false);
                                    if (e && e.status) {
                                      setAlert({
                                        show: true,
                                        type: "success",
                                        title: t("LHCSection.Success"),
                                        message: t(
                                          "LHCSection.Toss LHC Success"
                                        ),
                                        callback: () =>
                                          setSellDialogVisible(false),
                                      });
                                    } else {
                                      onErrorShow(
                                        t(
                                          "LHCSection.Toss LHC Fails, please try again"
                                        )
                                      );
                                    }
                                  },
                                  (e) => {
                                    setLoading(false);

                                    const errorResponse = e.message;
                                    const startIndex =
                                      errorResponse.indexOf('message":"') +
                                      'message":"'.length;
                                    const endIndex = errorResponse.indexOf(
                                      '",',
                                      startIndex
                                    );
                                    const errorReason = errorResponse.substring(
                                      startIndex,
                                      endIndex
                                    );

                                    onErrorShow(
                                      errorReason ||
                                        t(
                                          "LHCSection.Toss LHC Fails, please try again"
                                        ),
                                      () => {
                                        setSellDialogVisible(false);
                                      }
                                    );
                                  }
                                )
                              );
                            } else {
                              onErrorShow(
                                t(
                                  "LHCSection.Approve LHC Fails, please try again"
                                )
                              );
                            }
                          },
                          (e) => {
                            setLoading(false);

                            const errorResponse = e.message;
                            const startIndex =
                              errorResponse.indexOf('message":"') +
                              'message":"'.length;
                            const endIndex = errorResponse.indexOf(
                              '",',
                              startIndex
                            );
                            const errorReason = errorResponse.substring(
                              startIndex,
                              endIndex
                            );

                            onErrorShow(
                              errorReason ||
                                t(
                                  "LHCSection.Approve LHC Fails, please try again"
                                ),
                              () => {
                                setSellDialogVisible(false);
                              }
                            );
                          }
                        )
                      );
                    }
                  }}
                />
              )}
            </div>
            <div className="orderList-tools">
              <i
                className="fa-solid fa-arrows-rotate"
                onClick={() => reloadOrderListHandler()}
                disabled={LHCSellListLoading}
              ></i>
              {LHCSellListSort === "desc" ? (
                <i
                  className="fas fa-sort-amount-down"
                  onClick={() => setLHCSellListSort("asc")}
                ></i>
              ) : (
                <i
                  className="fas fa-sort-amount-up-alt"
                  onClick={() => setLHCSellListSort("desc")}
                ></i>
              )}
            </div>
            <div className="orderList-area">
              {LHCSellListLoading ? (
                <div className="fa-3x orderList-area-loading">
                  <i className="fas fa-spinner fa-pulse"></i>
                </div>
              ) : (
                <>
                  {LHCSellList && LHCSellList.length > 0 ? (
                    LHCSellList.sort((a, b) =>
                      LHCSellListSort === "desc"
                        ? b.originalTimestamp - a.originalTimestamp
                        : a.originalTimestamp - b.originalTimestamp
                    ).map((sellInfo) => (
                      <div
                        className="orderList-area-withData"
                        key={sellInfo.id}
                      >
                        <div className="withDataContent">
                          <div className="withDataInfo">
                            <span>
                              {numberFormat({
                                number: sellInfo.lhcAmount || 0,
                                decimalPoint: 0,
                              })}{" "}
                              /{" "}
                              {numberFormat({
                                number: sellInfo.lhcTotal || 0,
                                decimalPoint: 0,
                              })}
                              <span className="withDataInfo-unit">LHC</span>
                              {viewportType === "isMobile" && (
                                <div className="timeInfo">
                                  <span>{sellInfo.timestamp} (UTC+0)</span>
                                </div>
                              )}
                            </span>
                          </div>
                          {viewportType !== "isMobile" && (
                            <div className="timeInfo">
                              <span>{sellInfo.timestamp} (UTC+0)</span>
                            </div>
                          )}
                          <button
                            className="withDataButton"
                            onClick={() => {
                              if (
                                !walletController ||
                                !walletController.wallet ||
                                !navAccount
                              )
                                return;
                              setAlert({
                                show: true,
                                type: "question",
                                title: t("WebSide.would you redeem"),
                                message: t(
                                  "WebSide.redeem will be charged fee",
                                  {
                                    fee: LHCCancelTossFee,
                                  }
                                ),
                                showCloseBtn: true,
                                callback: async () => {
                                  if (
                                    !walletController ||
                                    !walletController.wallet ||
                                    !navAccount ||
                                    !sellInfo ||
                                    sellInfo.iToss === null
                                  )
                                    return;
                                  const account =
                                    await walletController.getAccount();

                                  if (!account) return;

                                  setLoading(true);
                                  walletController.send(
                                    new PostLHCWormholeCancelTossRequest(
                                      sellInfo.iToss,
                                      LHCCancelTossFee,
                                      account,
                                      (e) => {
                                        setLoading(false);
                                        setAlert({
                                          show: true,
                                          type: "info",
                                          title: t(
                                            "LHCSection.Waiting for transition"
                                          ),
                                          message: t(
                                            "LHCSection.Please do not refresh this page"
                                          ),
                                        });
                                      },
                                      (e) => {
                                        setLoading(false);
                                        onErrorShow(e.toString() ?? "onReject");
                                      },
                                      (e) => {
                                        setLoading(false);
                                        onErrorShow(e.toString() ?? "onError");
                                      },
                                      (e) => {
                                        setLoading(false);
                                        if (e && e.status) {
                                          setAlert({
                                            show: true,
                                            type: "success",
                                            title: t("LHCSection.Success"),
                                            message: t(
                                              "LHCSection.Cancel Toss Success"
                                            ),
                                            callback: () =>
                                              setSellDialogVisible(false),
                                          });
                                        } else {
                                          onErrorShow(
                                            t(
                                              "LHCSection.Cancel Toss Fails, please try again"
                                            )
                                          );
                                        }
                                      },
                                      (e) => {
                                        setLoading(false);

                                        const errorResponse = e.message;
                                        const startIndex =
                                          errorResponse.indexOf('message":"') +
                                          'message":"'.length;
                                        const endIndex = errorResponse.indexOf(
                                          '",',
                                          startIndex
                                        );
                                        const errorReason =
                                          errorResponse.substring(
                                            startIndex,
                                            endIndex
                                          );

                                        onErrorShow(
                                          errorReason ||
                                            t(
                                              "LHCSection.Cancel Toss Fails, please try again"
                                            ),
                                          () => {
                                            setSellDialogVisible(false);
                                          }
                                        );
                                      }
                                    )
                                  );
                                },
                              });
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="orderList-area-noData">
                      <img
                        className="noDataImg"
                        src={cyberLilyImg}
                        alt="cyber lily"
                      />
                      <p className="noDataText">No Data</p>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <AcceptCookie
        haveAcceptCookie={haveAcceptCookie}
        setHaveAcceptCookie={setHaveAcceptCookie}
      />
    </div>
  );
};

export default forwardRef(WebSide);
