import React from 'react';
import { useTranslation } from 'react-i18next';

const AcceptCookie = ({haveAcceptCookie, setHaveAcceptCookie}) => {
	const { t } = useTranslation();

	const acceptCookie = () => {
		setHaveAcceptCookie(true);
		window.localStorage.setItem('haveAcceptCookie', 'true');
	};

	const rejectCookie = () => {
		setHaveAcceptCookie(true);
	};

	return (
		<div id='AcceptCookie' className={Boolean(haveAcceptCookie) ? 'hide' : ''}>
			<div className='text-wrap'>
				{t('acceptCookie')}
			</div>

			<div className='buttons'>
				<div className='button deny' onClick={rejectCookie}>{t('Deny')}</div>
				<div className='button accept' onClick={acceptCookie}>{t('Accept')}</div>
			</div>
		</div>
	);
};

export default AcceptCookie;