import { ContractRequest, Web3Role } from "web3wallet/dist/web3wallet";

const Web3 = window['Web3'];

export class GetLHCWormholeIsEnoughLHCRequest extends ContractRequest {
    constructor(
        account,
        value,
        confirmCallback,
        rejectCallback,
        errorCallback
    ) {
        super(
            "Wormhole",
            "isEnoughLhc",
            Web3Role.Sender,
            confirmCallback,
            rejectCallback,
            errorCallback
        );

        this.account = account;
        this.value = value;
    }

    send(contract) {
        const method = this.getMethod();
        contract.methods[method](
            Web3.utils.toWei(this.value.toString())
        ).call(
            {
                from: this.account,
            },
            this.handleResponse.bind(this)
        ).catch(this.handleError.bind(this));
    }
}