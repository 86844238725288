import React from 'react';
import useLoading from '../../store/useLoading';
import useLoadingToConnecting from '../../store/useLoadingToConnecting';
import imgBase from './assets/base.svg';
import imgFluff from './assets/fluff.svg';

const Loading = () => {
	const [loading] = useLoading();
	const [loadingToConnecting] = useLoadingToConnecting();

	return (
		<div id='Loading' className={loading || loadingToConnecting ? 'active' : ''}>
			<div className='center-wrap'>
				<img src={imgBase} alt='' className='base' />

				<div className='text-wrap'>
					<div className='text'>{loading ? 'Loading' : 'Loading To Connecting'}</div>
					<div className='dot dot1'>.</div>
					<div className='dot dot2'>.</div>
					<div className='dot dot3'>.</div>
				</div>

				<img src={imgFluff} alt='' className='fluff' />
			</div>
		</div>
	);
};

export default Loading;