import React from 'react';
import { useTranslation } from "react-i18next";

const Game = () => {
	const { t } = useTranslation();

	return (
		<section id='GAMEsection' className='Game section'>
			<div className='section-content'>
				<div className='title'>WELCOME TO LLAMA LAND GAMEFi</div>
				<div className='sub'>{t('GameSection.description')}</div>

				<div className='flex-wrap'>
					<div className='item i1'>
						<div className='t1'>NFT</div>
						<div className='t2'>{t('GameSection.unique llamas collect', { llamaNumber: 8888 })}</div>
					</div>

					<div className='item i2'>
						<div className='t1'>{t('GameSection.Upgrade')}</div>
						<div className='t2'>{t('GameSection.upgrade your Llama')}</div>
					</div>

					<div className='item i3'>
						<div className='t1'>{t('GameSection.Summon')}</div>
						<div className='t2'>{t('GameSection.create a new generation')}</div>
					</div>

					<div className='item i4'>
						<div className='t1'>{t('GameSection.Season and Events')}</div>
						<div className='t2'>{t('GameSection.Collect and max out')}</div>
					</div>

					<div className='item i5'>
						<div className='t1'>{t('GameSection.Competiton')}</div>
						<div className='t2'>{t('GameSection.Select your Llama')}</div>
					</div>

					<div className='item i6'>
						<div className='t1'>{t('GameSection.Trading')}, {t('GameSection.Ranking')}</div>
						<div className='t2'>{t('GameSection.Trade on Market place')}</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Game;