import React, { useState, useEffect } from "react";
import { disablePageScroll, enablePageScroll } from "scroll-lock";
import WalletController from "../../controller/WalletController";
import onScrollHandler from "../../utils/scrollTo";
import imgLogo from "./assets/logo-min.png";
import imgLinkElement from "./assets/imgLinkElement.png";
import imgLink3 from "./assets/link3.svg";
import imgLink4 from "./assets/link4.svg";
import imgLink5 from "./assets/link5.svg";
import imgLinkRelic from "./assets/imgLinkRelic.png";
import imgLink1ElementHover from "./assets/imgLinkElementHover.png";
import imgLink3Hover from "./assets/link3-hover.svg";
import imgLink4Hover from "./assets/link4-hover.svg";
import imgLink5Hover from "./assets/link5-hover.svg";
import imgLinkRelicHover from "./assets/imgLinkRelicHover.png";
import imgLlama from "./assets/llama-min.png";
import imgMetamask from "./assets/metamask.svg";
import imgConnect from "./assets/connect.svg";
import imgInstall from "./assets/install.svg";
import imgSwitch from "./assets/switch.svg";
import imgClose from "./assets/close.svg";
import imgNavClose from "./assets/nav-close.svg";
import imgNavOpen from "./assets/nav-open.svg";
import playBtn from "./assets/play-small.png";
import lineImg from "./assets/line.png";
import i18nZhTwBtn from "./assets/i18n_zh_Tw.png";
import i18nEnBtn from "./assets/i18n_en.png";
import useENV from "../../store/useENV";
import { EXTERNAL_LINK } from "../../configs/link";
import { useTranslation } from "react-i18next";

const Navigation = (props) => {
  const { ZH_TW_WHITE_PAPER, WHITE_PAPER, DISCORD, TWITTER } = EXTERNAL_LINK;
  const { i18n } = useTranslation();
  const env = useENV()[0];
  const {
    viewportType,
    dialogue,
    account,
    setDialogue,
    onClickConnect,
    chainId,
    isCorrectChainHandler,
    resetAccount,
    initContract,
    gameStep, // 是否是開放遊戲階段
  } = props;
  const [mobileMenu, setMobileMenu] = useState(false);

  // 修正 safari, chrome 瀏覽器 100vh 無法剛好一整頁的問題
  // 詳細可參考 https://tools.wingzero.tw/article/sn/225
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  const vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty("--vh", `${vh}px`);

  useEffect(() => {
    const scrollableElement = document.querySelector(".nav-links");

    if (dialogue || (viewportType === "isMobile" && mobileMenu)) {
      disablePageScroll(scrollableElement);
    } else {
      enablePageScroll(scrollableElement);
    }
  }, [mobileMenu, viewportType, dialogue]);

  const closeDialogue = () => {
    setDialogue("");
  };

  const toggleMobileMenu = () => {
    setMobileMenu(!mobileMenu);
  };

  function startConnect() {
    closeDialogue();
    onClickConnect().then((r) => "");
  }

  const isMobileDevice = () => {
    const mobileDevice = [
      "Android",
      "webOS",
      "iPhone",
      "iPad",
      "iPod",
      "BlackBerry",
      "Windows Phone",
    ];
    let isMobileDevice = mobileDevice.some((e) => navigator.userAgent.match(e));
    return isMobileDevice;
  };

  const startInstall = () => {
    if (!isMobileDevice()) {
      window.open(
        "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn",
        "_blank"
      );
    } else {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        //判断iPhone|iPad|iPod|iOS
        window.open(
          "https://apps.apple.com/tw/app/metamask-blockchain-wallet/id1438144202",
          "_blank"
        );
      } else if (/(Android)/i.test(navigator.userAgent)) {
        //判断Android
        window.open(
          "https://play.google.com/store/apps/details?id=io.metamask",
          "_blank"
        );
      } else {
        //pc
      }
    }
  };

  return (
    <div id="Navigation" className={dialogue ? "no-blur" : ""}>
      <div id="Navs" className="webSite">
        <div className="nav landing" data-menuanchor="LANDING">
          <div
            className="logo-a"
            onClick={() => {
              onScrollHandler(viewportType, "LANDING");
            }}
          >
            <img src={imgLogo} alt="LLAMA LAND logo" className="logo" />
          </div>
        </div>
        <div className={`nav-links ${mobileMenu ? "show" : ""}`}>
          <div className="nav-link-areas">
            <div className="navigation-links">
              <div className="nav" data-menuanchor="MINT">
                <a href="#MINT" onClick={() => setMobileMenu(false)}>
                  MINT
                </a>
              </div>

              <div className="nav" data-menuanchor="ABOUT">
                <a href="#ABOUT" onClick={() => setMobileMenu(false)}>
                  ABOUT
                </a>
              </div>

              <div className="nav" data-menuanchor="NFT">
                <a href="#NFT" onClick={() => setMobileMenu(false)}>
                  NFT
                </a>
              </div>

              <div className="nav" data-menuanchor="GAME">
                <a href="#GAME" onClick={() => setMobileMenu(false)}>
                  GAME
                </a>
              </div>

              <div className="nav" data-menuanchor="VIDEO">
                <a href="#VIDEO" onClick={() => setMobileMenu(false)}>
                  VIDEO
                </a>
              </div>

              <div className="nav" data-menuanchor="LHC">
                <a href="#LHC" onClick={() => setMobileMenu(false)}>
                  $LHC
                </a>
              </div>

              <div className="nav" data-menuanchor="ROADMAP">
                <a href="#ROADMAP" onClick={() => setMobileMenu(false)}>
                  ROADMAP
                </a>
              </div>

              <div className="nav" data-menuanchor="TEAM">
                <a href="#TEAM" onClick={() => setMobileMenu(false)}>
                  TEAM
                </a>
              </div>

              <div className="nav" data-menuanchor="FQ">
                <a href="#FQ" onClick={() => setMobileMenu(false)}>
                  F&amp;Q
                </a>
              </div>

              {gameStep && (
                <div className="nav" data-menuanchor="PLAYENTRY">
                  <a href="#PLAYENTRY" onClick={() => setMobileMenu(false)}>
                    <img src={playBtn} alt="PLAYENTRY" className="playBtn" />
                  </a>
                </div>
              )}
            </div>
            <div className="links webSite">
              <a
                href={env.ELEMENT_COLLECTION_URL}
                target="_blank"
                rel="noreferrer noopener"
              >
                <img src={imgLinkElement} alt="element" className="logo" />
                <img
                  src={imgLink1ElementHover}
                  alt="element hover"
                  className="hover"
                />
                <div className="shadow"></div>
              </a>

              <a
                onClick={() => window.open(env.ELEMENT_RELIC_COLLECTION_URL)}
                target="_blank"
                rel="noreferrer noopener"
              >
                <img src={imgLinkRelic} alt="element relic" className="logo" />
                <img
                  src={imgLinkRelicHover}
                  alt="element relic hover"
                  className="hover"
                />
                <div className="shadow"></div>
              </a>

              <a href={DISCORD} target="_blank" rel="noreferrer noopener">
                <img src={imgLink3} alt="" className="logo" />
                <img src={imgLink3Hover} alt="" className="hover" />
                <div className="shadow"></div>
              </a>

              <a href={TWITTER} target="_blank" rel="noreferrer noopener">
                <img src={imgLink4} alt="" className="logo" />
                <img src={imgLink4Hover} alt="" className="hover" />
                <div className="shadow"></div>
              </a>

              <a
                href={i18n.language === "en" ? WHITE_PAPER : ZH_TW_WHITE_PAPER}
                target="_blank"
                rel="noreferrer noopener"
              >
                <img src={imgLink5} alt="" className="logo" />
                <img src={imgLink5Hover} alt="" className="hover" />
                <div className="shadow"></div>
              </a>

              <img className="lineImg" src={lineImg} alt="|" />

              <span
                className="i18nBtn"
                onClick={() => {
                  const switchLang = i18n.language === "en" ? "zh-Tw" : "en";
                  i18n.changeLanguage(switchLang);
                  window.localStorage.setItem("lang", switchLang);
                  if (isMobileDevice()) {
                    toggleMobileMenu();
                  }
                }}
              >
                <img
                  className="logo i18nBtnImg"
                  src={i18n.language === "en" ? i18nEnBtn : i18nZhTwBtn}
                  alt=""
                />
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="others">
        <div className="button" onClick={onClickConnect}>
          <div className={`text ${account ? "glow" : ""}`}>
            {account || "CONNECT"}
          </div>
        </div>
        <div className="mobile-menu" onClick={toggleMobileMenu}>
          <img
            src={imgNavClose}
            alt=""
            className={mobileMenu ? "active" : ""}
          />
          <img src={imgNavOpen} alt="" className={mobileMenu ? "" : "active"} />
        </div>
      </div>

      <div className={`dialogue ${dialogue === "connect" ? "active" : ""}`}>
        <div className="center-wrap">
          <div className="head purple">
            <img src={imgLlama} alt="Llama illustration" />
          </div>

          <div className="content">
            <div className="title">
              <img src={imgMetamask} alt="METAMASK" />
            </div>

            <div className="text">
              Connect to your <span>METAMASK</span> wallet
            </div>

            <div className="button-wrap">
              <div className="button" onClick={startConnect}>
                <img src={imgConnect} alt="CONNECT" />
              </div>
            </div>
          </div>

          <div className="close" onClick={closeDialogue}>
            <img src={imgClose} alt="close dialogue" />
          </div>
        </div>
      </div>

      <div className={`dialogue ${dialogue === "install" ? "active" : ""}`}>
        <div className="center-wrap">
          <div className="head purple">
            <img src={imgLlama} alt="Llama illustration" />
          </div>

          <div className="content">
            <div className="title">Download</div>

            <div className="text">
              Install <span>METAMASK</span> to continue
            </div>

            <div className="button-wrap">
              <div className="button" onClick={startInstall}>
                <img src={imgInstall} alt="INSTALL" />
              </div>
            </div>
          </div>

          <div className="close" onClick={closeDialogue}>
            <img src={imgClose} alt="close dialogue" />
          </div>
        </div>
      </div>

      <div className={`dialogue ${dialogue === "switch" ? "active" : ""}`}>
        <div className="center-wrap">
          <div className="head blue">
            <img src={imgLlama} alt="Llama illustration" />
          </div>

          <div className="content">
            <div className="title">Oops!</div>

            <div className="text">Please connect to the mainnet</div>

            <div className="button-wrap">
              <div
                className="button"
                onClick={() => {
                  if (
                    WalletController.wallet &&
                    WalletController.sender &&
                    chainId
                  ) {
                    WalletController.sender
                      .switchChain(chainId)
                      .then(() => {
                        closeDialogue();
                        isCorrectChainHandler();
                      })
                      .catch(() => {
                        resetAccount();
                      });
                  } else {
                    closeDialogue();
                  }
                }}
              >
                <img src={imgSwitch} alt="SWITCH" />
              </div>
            </div>
          </div>

          <div
            className="close"
            onClick={() => {
              initContract();
              closeDialogue();
            }}
          >
            <img src={imgClose} alt="close dialogue" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
