import React, { useEffect } from 'react';
import imgCharacter from './assets/character-min.png';
import imgBox1 from './assets/box1-min.png';
import imgBox2 from './assets/box2-min.png';
import imgBox3 from './assets/box3-min.png';
import { useTranslation } from "react-i18next";

const NFT = ({ step, mintPrice }) => {
	const { t, i18n } = useTranslation();
	const [stageI18n, setStageI18n] = React.useState('');

	useEffect(() => {
		switch(step) {
			case 'comingsoon':
				setStageI18n(t('NFTSection.comingSoon'));
				break;
			case 'presale':
				setStageI18n(t('NFTSection.preSale'));
				break;
			case 'opensale':
				setStageI18n(t('NFTSection.openSale'));
				break;
			case 'end':
				setStageI18n(t('NFTSection.end'));
				break;
			default:
				setStageI18n('');
				break;
		}
	}, [step, t]);

	return (
		<section id='NFTsection' className='NFT section'>
			<div className='section-content'>
				<div className='title'>8888 UNIQUE LLAMA</div>
				<div className='sub'>{t('NFTSection.Each Llama has six attributes')}</div>

				<div className='flex-wrap'>
					<div className='box-wrap wrap1'>
						<div className='box b1'>
							<img src={imgBox1} alt='' />
							<div className='va'>
								<div className='t1'>{t('NFTSection.number Llamas', { llamaNumber: 8888 })}</div>
								<div className='t2'>{t('NFTSection.public sell number BNB', { bnbNumber: mintPrice || '??' })}</div>
							</div>
						</div>

						<div className='box b2'>
							<img src={imgBox2} alt='' />
							<div className='va'>
								<div className='t1'>{t('NFTSection.Upgrade')} / {t('NFTSection.Summon')} </div>
							</div>
						</div>
					</div>

					<div className='center'>
						<img src={imgCharacter} alt='' />

						<div className={i18n.language === 'zh-Tw' ? 'box box1 box-zh' :'box box1'}>
							<div className='number'>5</div>
							<div className={i18n.language === 'zh-Tw' ? 'text text-zh' : 'text'}>{t('NFTSection.Type of')}{i18n.language === 'en' && <br />}{t('NFTSection.mouths')}</div>
						</div>

						<div className={i18n.language === 'zh-Tw' ? 'box box2 box-zh' :'box box2'}>
							<div className='number'>9</div>
							<div className={i18n.language === 'zh-Tw' ? 'text text-zh' : 'text'}>{t('NFTSection.Type of')}{i18n.language === 'en' && <br />}{t('NFTSection.eyes')}</div>
						</div>

						<div className={i18n.language === 'zh-Tw' ? 'box box3 box-zh' :'box box3'}>
							<div className='number'>55</div>
							<div className={i18n.language === 'zh-Tw' ? 'text text-zh' : 'text'}>{t('NFTSection.Hat')} / {t('NFTSection.Clothes')} /<br />{t('NFTSection.Belts')} / {t('NFTSection.Hand Items')} /<br />{t('NFTSection.Backpacks')}</div>
						</div>

						<div className={i18n.language === 'zh-Tw' ? 'box box4 box-zh' : 'box box4'}>
							<div className='number'>4</div>
							<div className={i18n.language === 'zh-Tw' ? 'text text-zh' : 'text'}>{t('NFTSection.Races')}</div>
						</div>
					</div>

					<div className='box-wrap wrap2'>
						<div className='box b3'>
							<img src={imgBox3} alt='' />
							<div className='va'>
								<div className='t1'>{t('NFTSection.Blind box system')}</div>
							</div>
						</div>

						<div className='box b4 TBA'>
							<div className='va'>
								<div className='t1 sp'>{t('NFTSection.Current stage')}</div>
								<div className='t1 sp'>"{stageI18n}"</div>

								<div className='datetime'>
									{/* <div className='date'>
										<div className='month'>APR</div>
										<div className='day'>05</div>
									</div>

									<div className='time'>"{t('NFTSection.Allowlist Mint')}"</div> */}

									<div className='TBA'>
										<span>
											{
												step === 'comingsoon' ? 'TBA' : step === 'end' ? 'Sold Out' : 'LIVE'
											}
										</span>
									</div>
								</div>
							</div>

							{/* <div className='va'> */}
								{/* <div className='t2'>public sell <span>0000 Llama</span>, 0000 of Llama will be saved for promotion use.</div> */}
							{/* </div> */}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default NFT;