import React, { useRef, useMemo } from 'react';
import Slider from 'react-slick';
import imgMember1 from './assets/member1-min.png';
import imgMember2 from './assets/member2-min.png';
import imgMember3 from './assets/member3-min.png';
import imgMember4 from './assets/member4-min.png';
import imgMember5 from './assets/member5-min.png';
import imgMember6 from './assets/member6-min.png';
import imgMember7 from './assets/member7-min.png';
import imgMember8 from './assets/member8-min.png';
import imgMember9 from './assets/member9-min.png';
import imgMember10 from './assets/member10-min.png';
import imgMember11 from './assets/member11-min.png';
import imgPrev from './assets/prev-min.png';
import imgNext from './assets/next-min.png';

const PrevArrow = (props) => {
	return (
		<div className={`arrow prev ${props.className}`}>
			<img src={imgPrev} alt='上一張' onClick={props.onClick} />
		</div>
	);
};

const NextArrow = (props) => {
	return (
		<div className={`arrow next ${props.className}`}>
			<img src={imgNext} alt='下一張' onClick={props.onClick} />
		</div>
	);
};

const Team = (props) => {
	const { viewportType } = props;
	const slider = useRef(null);

	const settings = useMemo(() => {
		let slidesToShow = 5;
		if (viewportType === 'isPadPortrait' || viewportType === 'isPadLandscape') slidesToShow = 3;
		if (viewportType === 'isMobile') slidesToShow = 1;

		let centerMode = false;
		if (viewportType === 'isMobile' || viewportType === 'isPadLandscape') centerMode = true;

		let centerPadding = '60px';
		if (viewportType === 'isMobile') centerPadding = `${Math.round(window.innerWidth / 100 * 17)}px`;
		if (viewportType === 'isPadLandscape') centerPadding = `${Math.round(window.innerWidth / 100 * 12)}px`;

		return {
			dots: true,
			slidesToShow,
			swipeToSlide: true,
			prevArrow: <PrevArrow />,
			nextArrow: <NextArrow />,
			centerMode,
			centerPadding,
			customPaging: () => {
				return (
					<div className='custom-dot-wrap'>
						<div className='custom-dot'>
							<div className='cover'></div>
						</div>
					</div>
				);
			},
		};
	}, [viewportType]);

	return (
		<section id='TEAMsection' className='Team section'>
			<div className='section-content'>
				<div className='title'>OUR TEAM</div>

				<div className='slider-wrap'>
					<Slider {...settings} ref={slider}>
						<div className='item'>
							<img src={imgMember1} alt='Artist 0xLi”Lee' />
						</div>

						<div className='item'>
							<img src={imgMember2} alt='Creative Director R&D Engineer 0x66737676' />
						</div>

						<div className='item'>
							<img src={imgMember3} alt='CMO Community Development 0xŘazorblade818' />
						</div>

						<div className='item'>
							<img src={imgMember4} alt='CEO Full Stack Engineer 0xVINCE' />
						</div>

						<div className='item'>
							<img src={imgMember5} alt='Quality Designer 0xiPerúvy' />
						</div>

						<div className='item'>
							<img src={imgMember6} alt='Lead Developer 0xKevin' />
						</div>

						<div className='item'>
							<img src={imgMember7} alt='Game Designer 0xJerry' />
						</div>

						<div className='item'>
							<img src={imgMember8} alt='Animator 0xCreepyLex' />
						</div>

						<div className='item'>
							<img src={imgMember9} alt='Animator 0xLiam' />
						</div>

						<div className='item'>
							<img src={imgMember10} alt='Animator 0xRex' />
						</div>

						<div className='item'>
							<img src={imgMember11} alt='Animator 0xMooncake' />
						</div>
					</Slider>
				</div>

			</div>
		</section >
	);
};

export default Team;