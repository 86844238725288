import React, { useEffect, useState, useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useWindowSize } from "react-use";
import axios from "axios";
import useAlert from "./store/useAlert";
import WebSide from "./sections/WebSide/WebSide";
import Alert from "./components/Alert/Alert";
import Navigation from "./components/Navigation/Navigation";
import WalletController from "./controller/WalletController";
import useLoading from "./store/useLoading";
import useLoadingToConnecting from "./store/useLoadingToConnecting";
import Loading from "./components/Loading/Loading";
import useENV from "./store/useENV";
import Wallet from "./Wallet";

const App = () => {
  const location = useLocation();
  const webSideRef = useRef();
  const [alert, setAlert] = useAlert();
  const setLoading = useLoading()[1];
  const setLoadingToConnecting = useLoadingToConnecting()[1];
  const [isInitLoading, setIsInitLoading] = useState(true);
  const [env, setENV] = useENV();
  const [dialogue, setDialogue] = useState(""); // connect, install, switch
  const [account, setAccount] = useState("");
  const { width, height } = useWindowSize();
  const [step, setStep] = useState("end"); // step 1.comingsoon 2.presale 3.opensale 4.end (為了讓子元件可以不初始化合約前提知道進度，進而顯示 Mint Button)
  const [phase, setPhase] = useState(
    window.localStorage.getItem("phase") || "game"
  ); // phase 1.mint 2.game
  const [gamehallUrl, setGamehallUrl] = useState("");
  const [arenaUrl, setArenaUrl] = useState("");

  const resetHandler = () => {
    setLoadingToConnecting(true);
    initContract();
  };

  function formatAccount(account) {
    const maxLen = 8;
    if (!account || account.length < maxLen) {
      return;
    }
    setAccount(account.substring(0, maxLen));

    window.account = account;
  }

  function resetAccount() {
    setAccount(undefined);
    window.account = "";
  }

  function displaySwitchConnect() {
    setAccount(undefined);
    setDialogue("switch");
    window.account = "";
  }

  const controllerListener = {
    onChangeAccount: (obj) => {
      if (obj.length > 0) {
        const account = obj[0];
        formatAccount(account);
        isCorrectChainHandler();
        initContract();
      } else {
        setAccount(undefined);
        setLoadingToConnecting(false);
        window.account = "";
        WalletController.wallet = null;
        WalletController.sender = null;
        window.location.reload();
        // 沒有 Account 就是單純的 reset 一些 data
        // initContract();
      }
    },

    onNotExistProvider: () => {
      setDialogue("install");
    },

    onNotExistAccounts: () => {
      console.log("onNotExistAccounts");
    },

    onConnect: (wallet) => {
      // 判斷是否需要重置合約資訊
      // if (window.account !== account && window.account !== null) {
      // 	resetHandler();
      // }
    },

    onHandleUnknownEvent: (evt) => {
      console.warn("Unknown event: ", evt);
    },

    notConnect: (evt) => {
      setLoadingToConnecting(false);
    },

    onChangeChain: (obj) => {
      setDialogue("");
      // 只要換鏈就重新整理一次
      if (WalletController.sender.detectProvider()) {
        if (WalletController.wallet) {
          isCorrectChainHandler();
        } else {
          // 初始化錢包
          initWalletHander();
        }
      } else {
        setDialogue("install");
      }
    },
  };

  const viewportType = useMemo(() => {
    const ratio = width / height;
    if (width < 1024) return "isMobile";
    if (ratio <= 1024 / 1366) return "isPadPortrait";
    if (ratio <= 1024 / 768) return "isPadLandscape";
    if (ratio > 16 / 7) return "isDesktopNarrow";
    return "isDesktopNormal";
  }, [width, height]);

  useEffect(() => {
    const header = document.getElementById("Navigation");

    const handleScroll = function (e) {
      const scrollY =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollY > 10) {
        if (viewportType === "isDesktopNarrow") {
          header.style.backgroundColor = "#000000cc";
        }
      } else {
        header.style.backgroundColor = "transparent";
      }
    };
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      header.style.backgroundColor = "transparent";
    };
  }, [viewportType]);

  const requestChainHandler = (type, CHAIN_ID) => {
    if (WalletController.wallet && WalletController.sender) {
      WalletController.sender.requestChain().then((userChainId) => {
        if (userChainId === CHAIN_ID) {
          setDialogue("");
          if (type === "init") {
            setIsInitLoading(false);
            setTimeout(() => {
              initContract();
            }, 1000);
          } else if (type === "reset") {
            resetHandler();
          }
        } else {
          // 顯示提示切換網彈窗
          displaySwitchConnect();
        }
      });
    }
  };

  // 判斷是否在正確鏈上
  const isCorrectChainHandler = (CHAIN_ID = env.CHAIN_ID) => {
    // 如果在一開始 init 階段，不要繼續往下，會重複跑初始化
    if (isInitLoading) return;
    if (WalletController.wallet && WalletController.sender) {
      if (!CHAIN_ID) {
        // 再從環境檔拿一次 CHAIN_ID
        fetch(
          process.env.NODE_ENV === "development"
            ? `/env.json`
            : `./env.json?v${Math.random()}`
        )
          .then((response) => response.json())
          .then((json) => {
            const { CHAIN_ID: ENV_CHAIN_ID } = json;

            requestChainHandler("reset", ENV_CHAIN_ID);
          })
          .catch((err) => {
            console.error("Init Error, ", err);
            setLoading(false);
            setLoadingToConnecting(false);
          });
      } else {
        requestChainHandler("reset", CHAIN_ID);
      }
    } else {
      setLoading(false);
      setLoadingToConnecting(false);
    }
  };

  // 初始化錢包
  const initWalletHander = ({
    CHAIN_ID,
    RPC_URL,
    VERSION_REPO_ADDRESS,
    CHAIN_NAME,
    NATIVE_CURRENCY,
    BLOCK_EXPLORER_URLS,
  } = env) => {
    WalletController.initWallet(
      controllerListener,
      CHAIN_ID,
      RPC_URL,
      VERSION_REPO_ADDRESS,
      CHAIN_NAME,
      NATIVE_CURRENCY,
      BLOCK_EXPLORER_URLS
    )
      .then(async (res) => {
        if (res) {
          // 判斷是否是正確鏈
          requestChainHandler("init", CHAIN_ID);
        }
      })
      .catch((err) => {
        setLoadingToConnecting(false);
        if (err === "different_chain_id") {
          // 顯示提示切換網彈窗
          displaySwitchConnect();
        }
      });
  };

  // 點擊連接
  const onClickConnect = async () => {
    if (WalletController.sender.detectProvider()) {
      if (WalletController.wallet) {
        WalletController.wallet
          .isConnected()
          .then(async (res) => {
            if (res && WalletController.initVersionRepoContract) {
              isCorrectChainHandler();
            } else {
              // 初始化錢包
              initWalletHander();
            }
          })
          .catch((err) => {
            initWalletHander();
          });
      } else {
        initWalletHander();
      }
    } else {
      setDialogue("install");
    }
  };

  // 初始化合約
  const initContract = async () => {
    if (!WalletController.wallet) return;
    WalletController.wallet.isConnected().then(async (res) => {
      if (res) {
        await webSideRef.current.initContract(WalletController);
      }
    });
  };

  useEffect(() => {
    window.account = null;
    setLoadingToConnecting(false);

    fetch(
      process.env.NODE_ENV === "development"
        ? `/env.json`
        : `${window.location.origin}/env.json?v${Math.random()}`
    )
      .then((response) => response.json())
      .then((json) => {
        const {
          API_ROUTER,
          CHAIN_ID,
          RPC_URL,
          VERSION_REPO_ADDRESS,
          CHAIN_NAME,
          NATIVE_CURRENCY,
          BLOCK_EXPLORER_URLS,
          LHC_CURRENCY,
          ELEMENT_COLLECTION_URL,
          ELEMENT_RELIC_COLLECTION_URL,
          ELEMENT_ACCOUNT_URL,
          ARENA_OPENED,
        } = json;

        setENV({
          API_ROUTER,
          CHAIN_ID,
          RPC_URL,
          VERSION_REPO_ADDRESS,
          CHAIN_NAME,
          NATIVE_CURRENCY,
          BLOCK_EXPLORER_URLS,
          LHC_CURRENCY,
          ELEMENT_COLLECTION_URL,
          ELEMENT_RELIC_COLLECTION_URL,
          ELEMENT_ACCOUNT_URL,
          ARENA_OPENED,
        });

        if (
          !API_ROUTER ||
          !CHAIN_ID ||
          !RPC_URL ||
          !VERSION_REPO_ADDRESS ||
          !CHAIN_NAME ||
          !NATIVE_CURRENCY ||
          !BLOCK_EXPLORER_URLS
        ) {
          setLoadingToConnecting(false);
          return;
        }

        // 判斷目前整體階段
        axios
          .get(`${API_ROUTER}/api/get-phase`)
          .then(async (res) => {
            const { data, status } = res;
            if (status === 200 && data.phase) {
              setPhase(data.phase);
              setGamehallUrl(data.gamehallUrl || "");
              setArenaUrl(data.arenaUrl || "");

              if (window.localStorage.getItem("phase") !== data.phase) {
                window.localStorage.setItem("phase", data.phase);
                window.location.reload();
              }
            } else {
              window.localStorage.setItem("phase", "mint");
              setPhase("mint");
            }
          })
          .catch(() => {
            window.localStorage.setItem("phase", "mint");
            setPhase("mint");
          });

        // 判斷目前第幾階段(再不初始化合約前提下顯示按鈕)
        axios
          .get(`${API_ROUTER}/api/get-step`)
          .then(async (res) => {
            const { data, status } = res;
            if (status === 200 && data.step) {
              setStep(data.step);
            }
          })
          .catch(() => {})
          .then(async () => {
            // 初始化錢包但是先不初始化合約
            const { web3Wallet: newWeb3Wallet, sender: newSender } =
              await Wallet.create(
                CHAIN_ID,
                RPC_URL,
                CHAIN_NAME,
                NATIVE_CURRENCY,
                BLOCK_EXPLORER_URLS
              );
            WalletController.wallet = newWeb3Wallet;
            WalletController.sender = newSender;
            newWeb3Wallet.isConnected().then(async (res) => {
              // 確定有登入在初始化
              if (res) {
                setIsInitLoading(true);
                // 判斷是否是正確鏈
                initWalletHander({
                  CHAIN_ID,
                  RPC_URL,
                  VERSION_REPO_ADDRESS,
                  CHAIN_NAME,
                  NATIVE_CURRENCY,
                  BLOCK_EXPLORER_URLS,
                });
              } else if (
                !res &&
                (location.pathname === "/market_place" ||
                  location.pathname.indexOf(`/market_place/detail/`) !== -1)
              ) {
                setAlert({
                  show: true,
                  type: "failure",
                  title: "Oops!",
                  message: "Please connect your wallet",
                  callback() {
                    window.location = "/";
                  },
                });
              }
            });
          });
      })
      .catch((err) => {
        console.error("Init Error, ", err);
        setLoadingToConnecting(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <div id="App" className={viewportType}>
      <Navigation
        viewportType={viewportType}
        dialogue={dialogue}
        setDialogue={setDialogue}
        onClickConnect={onClickConnect}
        account={account}
        chainId={env.CHAIN_ID}
        isCorrectChainHandler={isCorrectChainHandler}
        resetAccount={resetAccount}
        initContract={initContract}
        gameStep={phase === "game"}
      />

      <WebSide
        ref={webSideRef}
        alert={alert}
        viewportType={viewportType}
        propStep={step}
        propPhase={phase}
        formatAccount={formatAccount}
        navAccount={account}
        setDialogue={setDialogue}
        gamehallUrl={gamehallUrl}
        arenaUrl={arenaUrl}
        dialogue={dialogue}
        arenaOpened={env.ARENA_OPENED || false}
      />

      <Alert />

      <Loading />
    </div>
  );
};

export default App;
