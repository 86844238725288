import Wallet from "../Wallet";
import { Web3Wallet } from "web3wallet/dist/web3wallet";

const WalletController = {
    wallet: undefined,
    sender: undefined,
    listener: undefined,
    versionRepoContractName: undefined,
    versionRepoContractAbi: undefined,
    versionRepoContractAddress: undefined,
    llamaLandFreeFreeMintContractName: undefined,
    llamaLandFreeFreeMintContractAbi: undefined,
    llamaLandAllowListContractName: undefined,
    llamaLandAllowListContractAbi: undefined,
    llamaLandContractName: undefined,
    llamaLandContractAbi: undefined,
    llamaLandOriginContractName: undefined,
    llamaLandOriginContractAbi: undefined,
    llamaLandPlayerContractName: undefined,
    llamaLandPlayerContractAbi: undefined,
    lhcWormholeContractAbi: undefined,
    lhcWormholeContractName: undefined,
    lhcContractAddress: undefined,
    initWallet: (listener, CHAIN_ID, RPC_URL, VERSION_REPO_ADDRESS, CHAIN_NAME, NATIVE_CURRENCY, BLOCK_EXPLORER_URLS) => {
        return new Promise(async (resolve, reject) => {
            if (!CHAIN_ID || !RPC_URL || !VERSION_REPO_ADDRESS || !CHAIN_NAME || !NATIVE_CURRENCY || !BLOCK_EXPLORER_URLS) {
                reject();
            }
            if (self.wallet && self.sender) {
                self.wallet.isConnected()
                    .then((res) => {
                        if (res) {
                            self.sender.requestChain()
                                .then((userChainId) => {
                                    if (userChainId === CHAIN_ID) {
                                        resolve(res);
                                    } else {
                                        reject('different_chain_id');
                                    }
                                });
                        } else {
                            reject();
                        }
                    })
                    .catch(() => {
                        reject();
                    });
            }
            self.listener = listener;
            // TODO get the chain ID and RPC Url from external object
            // self.wallet = Wallet.create('0x38', 'https://bsc-dataseed1.binance.org/');
            const { web3Wallet: newWeb3Wallet, sender: newSender } = Wallet.create(CHAIN_ID, RPC_URL, CHAIN_NAME, NATIVE_CURRENCY, BLOCK_EXPLORER_URLS);
            self.wallet = newWeb3Wallet;
            self.sender = newSender;
            self.wallet.setListener(self);
            self.versionRepoContractAddress = VERSION_REPO_ADDRESS;
            await self.wallet.connect()
                .then((res) => {
                    if (res === Web3Wallet.CONNECTED) {
                        newSender.requestChain()
                            .then((userChainId) => {
                                if (userChainId === CHAIN_ID) {
                                    resolve(res);
                                } else {
                                    reject('different_chain_id');
                                }
                            });
                    } else {
                        reject(res);
                    }
                })
                .catch((err) => {
                    reject();
                });
        });
    },
    on: (evt, obj) => {
        switch (evt) {
            case Web3Wallet.PROVIDER_NOT_EXIST: self.listener.onNotExistProvider(obj); break;
            case Web3Wallet.ACCOUNTS_NOT_EXIST: self.listener.onNotExistAccounts(obj); break;
            case Web3Wallet.CHANGE_ACCOUNT: self.listener.onChangeAccount(obj); break;
            case Web3Wallet.CONNECTED: self.listener.onConnect(self.wallet); break;
            case Web3Wallet.NOT_CONNECTED: self.listener.notConnect(obj); break;
            case Web3Wallet.CHANGE_CHAIN: self.listener.onChangeChain(obj); break;
            default: self.listener.onHandleUnknownEvent(evt); break;
        }
    },
    connect: async () => {
        const result = await self.wallet.connect();
        self.on(result);
    },
    initVersionRepoContract: async () => {
        if (!self.wallet) return;
        self.versionRepoContractAbi = require('../../node_modules/abi-version-repo/abi/VersionRepo.json').abi;
        self.versionRepoContractName = require('../../node_modules/abi-version-repo/abi/VersionRepo.json').contractName;
        await self.wallet.setContract(
            self.versionRepoContractName,
            self.versionRepoContractAbi,
            self.versionRepoContractAddress
        );
    },
    // LlamaLand Origin 合約
    initLlamaLandOriginContract: async (llamaLandContractAddress) => {
        if (!self.wallet || !llamaLandContractAddress) return;
        self.llamaLandOriginContractAbi = require('../../node_modules/abi-llama-nft/abi/LlamaLandOrigin.json').abi;
        self.llamaLandOriginContractName = require('../../node_modules/abi-llama-nft/abi/LlamaLandOrigin.json').contractName;
        await self.wallet.setContract(
            self.llamaLandOriginContractName,
            self.llamaLandOriginContractAbi,
            llamaLandContractAddress
        );
    },
    // LlamaLand 合約
    initLlamaLandContract: async (llamaLandContractAddress) => {
        if (!self.wallet || !llamaLandContractAddress) return;
        self.llamaLandContractAbi = require('../../node_modules/abi-llama-nft/abi/LlamaLand.json').abi;
        self.llamaLandContractName = require('../../node_modules/abi-llama-nft/abi/LlamaLand.json').contractName;
        await self.wallet.setContract(
            self.llamaLandContractName,
            self.llamaLandContractAbi,
            llamaLandContractAddress
        );
    },
    // LlamaLand Free Mint 合約
    initLlamaLandFreeMintContract: async (llamaLandFreeFreeMintContractAddress) => {
        if (!self.wallet || !llamaLandFreeFreeMintContractAddress) return;
        self.llamaLandFreeFreeMintContractAbi = require('../../node_modules/abi-llama-nft/abi/LlamaLandFreeMint.json').abi;
        self.llamaLandFreeFreeMintContractName = require('../../node_modules/abi-llama-nft/abi/LlamaLandFreeMint.json').contractName;
        await self.wallet.setContract(
            self.llamaLandFreeFreeMintContractName,
            self.llamaLandFreeFreeMintContractAbi,
            llamaLandFreeFreeMintContractAddress
        );
    },
    // LlamaLand Allow List 合約
    initLlamaLandAllowListContract: async (llamaLandAllowListContractAddress) => {
        if (!self.wallet || !llamaLandAllowListContractAddress) return;
        self.llamaLandAllowListContractAbi = require('../../node_modules/abi-llama-nft/abi/LlamaLandAllowList.json').abi;
        self.llamaLandAllowListContractName = require('../../node_modules/abi-llama-nft/abi/LlamaLandAllowList.json').contractName;
        await self.wallet.setContract(
            self.llamaLandAllowListContractName,
            self.llamaLandAllowListContractAbi,
            llamaLandAllowListContractAddress
        );
    },
    // LlamaLand Player 合約
    initLlamaLandPlayerContract: async (llamaLandPlayerContractAddress) => {
        if (!self.wallet || !llamaLandPlayerContractAddress) return;
        self.llamaLandPlayerContractAbi = require('../../node_modules/abi-llama-nft/abi/LlamaLandPlayer.json').abi;
        self.llamaLandPlayerContractName = require('../../node_modules/abi-llama-nft/abi/LlamaLandPlayer.json').contractName;
        await self.wallet.setContract(
            self.llamaLandPlayerContractName,
            self.llamaLandPlayerContractAbi,
            llamaLandPlayerContractAddress
        );
    },
    // LHC 合約
    initLHCContract: async (lhcContractAddress) => {
        if (!self.wallet || !lhcContractAddress) return;
        self.lhcContractAbi = require('../../node_modules/abi-lhc/abi/LHC.json').abi;
        self.lhcContractName = require('../../node_modules/abi-lhc/abi/LHC.json').contractName;
        await self.wallet.setContract(
            self.lhcContractName,
            self.lhcContractAbi,
            lhcContractAddress
        );
    },
    // LHC Wormhole 合約
    initLHCWormholeContract: async (lhcWormholeContractAddress) => {
        if (!self.wallet || !lhcWormholeContractAddress) return;
        self.lhcWormholeContractAbi = require('../../node_modules/abi-lhc/abi/Wormhole.json').abi;
        self.lhcWormholeContractName = require('../../node_modules/abi-lhc/abi/Wormhole.json').contractName;
        self.lhcContractAddress = lhcWormholeContractAddress;
        await self.wallet.setContract(
            self.lhcWormholeContractName,
            self.lhcWormholeContractAbi,
            lhcWormholeContractAddress
        );
    },
    getAccount: async () => {
        return (await self.wallet.getAccounts())[0];
    },
    send: async (req) => {
        if (!req) return;
        const result = await self.wallet.send(req);
        return result;
    }
};
const self = WalletController;
export default WalletController;