/* eslint-disable no-script-url */
import React, { useEffect } from "react";
import LAND from "./assets/LAND.png";
import ARENA from "./assets/ARENA.png";
import ARENA_DISABLED from "./assets/ARENA-disabled.png";
import LAND_M from "./assets/LAND-m.png";
import ARENA_M from "./assets/ARENA-m.png";
import ARENA_M_DISABLED from "./assets/ARENA-m-disabled.png";
import WalletController from "../../controller/WalletController";

const PlayEntry = ({
  show,
  gamehallUrl,
  arenaUrl,
  viewportType,
  setDialogue,
  arenaOpened,
}) => {
  const openWindowHandler = (targetURL) => {
    if (!WalletController?.sender?.detectProvider()) {
      setDialogue("install");
      return;
    }

    window.location.href = targetURL;
  };

  useEffect(() => {
    console.log(gamehallUrl, arenaUrl);
  }, [gamehallUrl, arenaUrl]);

  return (
    <section
      id="PLAYENTRYsection"
      className={show ? "PlayEntry section show" : "PlayEntry section"}
    >
      <div className="section-content">
        {show && (
          <>
            {" "}
            {viewportType !== "isMobile" ? (
              <div className="entryRow">
                <div
                  className="card"
                  onClick={() => {
                    if (!gamehallUrl) return;
                    openWindowHandler(gamehallUrl);
                  }}
                >
                  <img src={LAND} alt="LAND" className="img" />
                </div>
                <div
                  className="card"
                  onClick={() => {
                    if (!arenaUrl || arenaOpened !== true) return;
                    openWindowHandler(arenaUrl);
                  }}
                >
                  <img
                    src={arenaOpened ? ARENA : ARENA_DISABLED}
                    alt="ARENA"
                    className="img"
                  />
                </div>
              </div>
            ) : (
              <div className="entryRow">
                <div
                  className="card"
                  onClick={() => {
                    if (!gamehallUrl) return;
                    openWindowHandler(gamehallUrl);
                  }}
                >
                  <img src={LAND_M} alt="LAND" className="img" />
                </div>
                <div
                  className="card"
                  onClick={() => {
                    if (!arenaUrl || arenaOpened !== true) return;
                    openWindowHandler(arenaUrl);
                  }}
                >
                  <img
                    src={arenaOpened ? ARENA_M : ARENA_M_DISABLED}
                    alt="ARENA"
                    className="img"
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </section>
  );
};

export default PlayEntry;
