import React from "react";
import imgJoinDiscord from "./assets/join-discord-min.png";
import { useTranslation } from "react-i18next";
import { EXTERNAL_LINK } from "../../configs/link";

const About = () => {
  const { DISCORD } = EXTERNAL_LINK;
  const { t } = useTranslation();

  return (
    <section id="ABOUTsection" className="About section">
      <div className="section-content">
        <div className="flex-title">
          <div className="t1">RISE AND JOIN</div>
          <div className="line"></div>
        </div>

        <div className="t2">THE LlAMA ARMY!</div>

        <div className="t3">{t("AboutSection.In 8858")}</div>

        <p>{t("AboutSection.According 3-1")}</p>

        <p>{t("AboutSection.The whole world changed")}</p>

        <p>{t("AboutSection.Faced with a strange alien")}</p>

        <p>{t("AboutSection.a world class")}</p>

        <div className="button-wrap">
          <a
            href={DISCORD}
            target="_blank"
            rel="noreferrer noopener"
            className="button"
          >
            <img src={imgJoinDiscord} alt="Join Discord" />
          </a>

          <div className="t4">{t("AboutSection.It starts with community")}</div>
        </div>
      </div>
    </section>
  );
};

export default About;
