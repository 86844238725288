import { ContractRequest, Web3Role } from "web3wallet/dist/web3wallet";

export class GetAllowListUnlockedAmountRequest extends ContractRequest {
    constructor(
        account,
        confirmCallback,
        rejectCallback,
        errorCallback
    ) {
        super(
            "LlamaLandAllowList",
            "unlockedAmount",
            Web3Role.Sender,
            confirmCallback,
            rejectCallback,
            errorCallback
        );

        this.account = account;
    }

    send(contract) {
        const method = this.getMethod();
        contract.methods[method]().call(
            {
                from: this.account
            },
            this.handleResponse.bind(this)
        ).catch(this.handleError.bind(this));
    }
}