import { ContractRequest, Web3Role } from "web3wallet/dist/web3wallet";

const Web3 = window["Web3"];

export class PostLHCWormholeCancelTossRequest extends ContractRequest {
  constructor(
    iToss,
    cancelTossFee,
    account,
    confirmCallback,
    rejectCallback,
    errorCallback,
    thenCallback,
    catchCallback
  ) {
    super(
      "Wormhole",
      "cancelToss",
      Web3Role.Sender,
      confirmCallback,
      rejectCallback,
      errorCallback
    );

    this.iToss = iToss;
    this.cancelTossFee = cancelTossFee;
    this.account = account;
    this.thenCallback = thenCallback;
    this.catchCallback = catchCallback;
  }

  send(contract) {
    const method = this.getMethod();
    contract.methods[method](Number(this.iToss))
      .send(
        {
          from: this.account,
          value: Web3.utils.toWei(this.cancelTossFee),
          type: 0,
        },
        this.handleResponse.bind(this)
      )
      .then(this.thenCallback)
      .catch((e) => {
        this.handleError.bind(this);
        this.catchCallback(e);
      });
  }
}
