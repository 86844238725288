import React, { useState, useMemo, useRef } from "react";
import { useWindowSize } from "react-use";
import imgMainD from "./assets/main-d-min.png";
import LHCIcon from "./assets/main-mobile-min.png";
import imgBlurD from "./assets/blur-d-min.png";
import addLHCToMetaMask_en from "./assets/add-LHC-to-MetaMask.png";
import LHCAddressBtn_en from "./assets/LHC-address-btn.png";
import { GetAddressRequest } from "versionrepo/dist/versionrepo";
import useAlert from "../../store/useAlert";
import { useTranslation } from "react-i18next";
import moment from "moment";
import CanvasTextImg from "./CanvasTextImg";
import LHCAbi from "abi-lhc/abi/LHC.json";
// import BNBAbi from '../../utils/abi-BNB.json';
import { GetLHCWormholeTossListRequest } from "../../request";

import addLHCToMetaMask_zhTW from "./assets/add-LHC-to-MetaMask-zh-TW.png";
import LHCAddressBtn_zhTW from "./assets/LHC-address-btn-zh-TW.png";
import LHCSell from "./assets/LHC-sell.png";
import LHCSell_zhTW from "./assets/LHC-sell-zh-TW.png";
import LHCBuy from "./assets/LHC-buy.png";
import LHCBuy_zhTW from "./assets/LHC-buy-zh-TW.png";
import LHCSellDisabled from "./assets/LHC-d-sell.png";
import LHCSellDisabled_zhTW from "./assets/LHC-d-sell-zh-TW.png";
import LHCBuyDisabled from "./assets/LHC-d-buy.png";
import LHCBuyDisabled_zhTW from "./assets/LHC-d-buy-zh-TW.png";

const LHC = ({
  WalletController,
  PropsLHCAddress,
  navAccount,
  env,
  setDialogue,
  viewportType,
  setBuyDialogVisible,
  setSellDialogVisible,
  setBNBBalanceOf,
  setLHCBalanceOf,
  setLHCSellList,
  setLHCSellListLoading,
  LHCAddressByWormhole,
}) => {
  const Web3 = window["Web3"];
  const buttonWrapRef = useRef(null);
  const { t, i18n } = useTranslation();
  const setAlert = useAlert()[1];
  const [LHCAddress, setLHCAddress] = useState(null);
  const { width } = useWindowSize();

  const LHCBuyBtn = useMemo(() => {
    if (i18n.language === "en") {
      return LHCBuy;
    } else {
      return LHCBuy_zhTW;
    }
  }, [i18n.language]);

  const LHCSellBtn = useMemo(() => {
    if (i18n.language === "en") {
      return LHCSell;
    } else {
      return LHCSell_zhTW;
    }
  }, [i18n.language]);

  const LHCBuyDisabledBtn = useMemo(() => {
    if (i18n.language === "en") {
      return LHCBuyDisabled;
    } else {
      return LHCBuyDisabled_zhTW;
    }
  }, [i18n.language]);

  const LHCSellDisabledBtn = useMemo(() => {
    if (i18n.language === "en") {
      return LHCSellDisabled;
    } else {
      return LHCSellDisabled_zhTW;
    }
  }, [i18n.language]);

  const availableTradingLHC = useMemo(() => {
    if (
      ((env && env.LHC_CURRENCY && env.LHC_CURRENCY.address) ||
        PropsLHCAddress) &&
      navAccount &&
      WalletController &&
      WalletController.wallet &&
      LHCAddressByWormhole &&
      (LHCAddressByWormhole === PropsLHCAddress ||
        (env &&
          env.LHC_CURRENCY &&
          env.LHC_CURRENCY.address &&
          LHCAddressByWormhole === env.LHC_CURRENCY.address))
    ) {
      return true;
    }
    return false;
  }, [
    LHCAddressByWormhole,
    PropsLHCAddress,
    WalletController,
    env,
    navAccount,
  ]);

  const addLHCToMetaMask = useMemo(() => {
    if (i18n.language === "en") {
      return addLHCToMetaMask_en;
    } else {
      return addLHCToMetaMask_zhTW;
    }
  }, [i18n.language]);

  const LHCAddressBtn = useMemo(() => {
    if (i18n.language === "en") {
      return LHCAddressBtn_en;
    } else {
      return LHCAddressBtn_zhTW;
    }
  }, [i18n.language]);

  const setLHCToWalletHandler = async (tokenAddress) => {
    try {
      const wasAdded = await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: tokenAddress,
            symbol:
              env && env.LHC_CURRENCY && env.LHC_CURRENCY.symbol
                ? env.LHC_CURRENCY.symbol
                : "LHC",
            decimals:
              env && env.LHC_CURRENCY && env.LHC_CURRENCY.decimals
                ? env.LHC_CURRENCY.decimals
                : 18,
            image:
              env && env.LHC_CURRENCY && env.LHC_CURRENCY.image
                ? env.LHC_CURRENCY.image
                : null,
          },
        },
      });

      if (wasAdded) {
        setAlert({
          show: true,
          type: "success",
          title: "Success!",
          message: "Token added Successfully!",
          callback() {},
        });
      } else {
        setAlert({
          show: true,
          type: "failure",
          title: "Oops!",
          message: "Failed to add the token!",
          callback() {},
        });
      }
    } catch (error) {
      const { message } = error;
      setAlert({
        show: true,
        type: "failure",
        title: "Oops!",
        message,
        callback() {},
      });
    }
  };

  const addLHCHandler = async () => {
    if (!WalletController) return;

    WalletController.wallet.isConnected().then(async (res) => {
      if (res) {
        const account = await WalletController.getAccount();

        if (!account) return;

        if (PropsLHCAddress) {
          setLHCToWalletHandler(PropsLHCAddress);
        } else if (!PropsLHCAddress && env?.LHC_CURRENCY?.address) {
          setLHCToWalletHandler(env.LHC_CURRENCY.address);
        } else {
          await WalletController.send(
            new GetAddressRequest(
              "LHC",
              account,
              async (e) => {
                setLHCAddress(e);
                const tokenAddress = e;

                setLHCToWalletHandler(tokenAddress);
              },
              (e) => {
                console.log(e);
              },
              (e) => {
                console.log(e);
              }
            )
          );
        }
      } else {
        // 顯示提示連結錢包
        setDialogue("connect");
      }
    });
  };

  const LHCAddressText = useMemo(() => {
    if (PropsLHCAddress) {
      return PropsLHCAddress;
    } else if (env && env?.LHC_CURRENCY?.address) {
      return env.LHC_CURRENCY.address;
    } else if (LHCAddress) {
      return LHCAddress;
    } else {
      return "";
    }
  }, [PropsLHCAddress, env, LHCAddress]);

  const openSellLHCModal = async () => {
    if (!availableTradingLHC || !WalletController) return;

    try {
      // 有錢包才讀取餘額
      if (typeof window.ethereum !== "undefined") {
        const web3 = new Web3(window.ethereum);

        window.ethereum
          .request({ method: "eth_requestAccounts" })
          .then(async ([address]) => {
            const tokenContract = new web3.eth.Contract(
              LHCAbi.abi,
              LHCAddressText
            );
            const balance = await tokenContract.methods
              .balanceOf(address)
              .call();
            const format = web3.utils.fromWei(balance, "ether");
            const account = await web3.eth.getAccounts().then((e) => e[0]);
            if (!isNaN(Number(format)) && Number(format) > 0) {
              // 只拿整數，無條件捨去小數點後
              setLHCBalanceOf(format.split(".")[0]);
            }

            // 拿到賣單列表
            await WalletController.send(
              new GetLHCWormholeTossListRequest(
                account,
                (e) => {
                  setLHCSellList(
                    e.map((tossInfo) => {
                      const { index, addr, timestamp, lhcTotal, lhcAmount } =
                        tossInfo;

                      return {
                        iToss: index,
                        id: addr + timestamp,
                        addr,
                        timestamp: moment(timestamp * 1000)
                          .utc()
                          .format("YYYY-MM-DD HH:mm:ss"),
                        originalTimestamp: timestamp,
                        lhcTotal: Web3.utils.fromWei(lhcTotal),
                        lhcAmount: Web3.utils.fromWei(lhcAmount),
                      };
                    })
                  );
                  setLHCSellListLoading(false);
                  setSellDialogVisible(true);
                },
                (e) => console.error("onReject", e),
                (e) => console.error("onError", e)
              )
            );
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const openBuyLHCModal = async () => {
    if (!availableTradingLHC) return;
    try {
      // 有錢包才讀取餘額
      if (typeof window.ethereum !== "undefined") {
        const web3 = new Web3(window.ethereum);

        window.ethereum
          .request({ method: "eth_requestAccounts" })
          .then(async ([address]) => {
            const balance = await web3.eth.getBalance(address);
            const format = web3.utils.fromWei(balance, "ether");
            if (!isNaN(Number(format)) && Number(format) > 0) {
              setBNBBalanceOf(format);
            }

            setBuyDialogVisible(true);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <section id="LHCsection" className="LHC section">
      <div className="section-content">
        <div className="title">$LHC TOKEN</div>
        <div className="sub">{t("LHCSection.description")}</div>
        {viewportType === "isMobile" && (
          <>
            <div className="LHCLogo">
              <img src={LHCIcon} alt="LHC For Mobile" className="mobile-lhc" />
            </div>
            <div className="buy-sell-area">
              <img
                className={
                  availableTradingLHC
                    ? "buy-sell-image"
                    : "buy-sell-image buy-sell-image-disabled"
                }
                src={availableTradingLHC ? LHCBuyBtn : LHCBuyDisabledBtn}
                alt="LHC Buy Button"
                onClick={openBuyLHCModal}
              />
              <img
                className={
                  availableTradingLHC
                    ? "buy-sell-image"
                    : "buy-sell-image buy-sell-image-disabled"
                }
                src={availableTradingLHC ? LHCSellBtn : LHCSellDisabledBtn}
                alt="LHC Sell Button"
                onClick={openSellLHCModal}
              />
            </div>
          </>
        )}

        <div className="main-content">
          <img src={imgBlurD} alt="" className="blur" />

          <div className="inner-content">
            <img src={imgMainD} alt="" className="desktop" />

            <div className="text-wrap wrap1">
              <div className="t1">{t("LHCSection.In-game Currency")}</div>
              <div className="t2">{t("LHCSection.In-game Currency Desc")}</div>
            </div>

            <div className="text-wrap wrap2">
              <div className="t1">{t("LHCSection.Governance")}</div>
              <div className="t2">{t("LHCSection.Governance Desc")}</div>
            </div>

            <div className="text-wrap wrap3">
              <div className="t1">Play And Earn</div>
              <div className="t2">{t("LHCSection.Play And Earn Desc")}</div>
            </div>

            <div className="text-wrap wrap4">
              <div className="t1">{t("LHCSection.WEB3 Economy")}</div>
              <div className="t2">{t("LHCSection.WEB3 Economy Desc")}</div>
            </div>
          </div>
        </div>

        <div className="button-wrap-area">
          <div
            className="button-wrap"
            ref={buttonWrapRef}
            style={{
              maxWidth: 500,
            }}
          >
            {viewportType !== "isMobile" && (
              <div className="buy-sell-area">
                <img
                  className={
                    availableTradingLHC
                      ? "buy-sell-image"
                      : "buy-sell-image buy-sell-image-disabled"
                  }
                  src={availableTradingLHC ? LHCBuyBtn : LHCBuyDisabledBtn}
                  alt="LHC Buy Button"
                  onClick={openBuyLHCModal}
                />
                <img
                  className={
                    availableTradingLHC
                      ? "buy-sell-image"
                      : "buy-sell-image buy-sell-image-disabled"
                  }
                  src={availableTradingLHC ? LHCSellBtn : LHCSellDisabledBtn}
                  alt="LHC Sell Button"
                  onClick={openSellLHCModal}
                />
              </div>
            )}
            {window.ethereum && window.ethereum.isMetaMask && (
              <div className="button">
                <img
                  src={addLHCToMetaMask}
                  alt="Join Discord"
                  onClick={() => addLHCHandler()}
                />
              </div>
            )}
            <a
              href={`${
                env && env.BLOCK_EXPLORER_URLS
                  ? env.BLOCK_EXPLORER_URLS
                  : "https://bscscan.com"
              }/address/${LHCAddressText}`}
              target="_blank"
              rel="noreferrer noopener"
              className="button"
            >
              <CanvasTextImg
                imgUrl={LHCAddressBtn}
                width={buttonWrapRef.current?.offsetWidth || 500}
                height={width < 460 ? 50 : 64}
                text={
                  width < 460
                    ? `${LHCAddressText.slice(0, 14)}...${LHCAddressText.slice(
                        -14,
                        LHCAddressText.length
                      )}`
                    : LHCAddressText
                }
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LHC;
