const onScrollHandler = (viewportType, slide, callback) => {
  var section = document.getElementById(`${slide}section`);

  if (section && viewportType === 'isMobile') {
    const offset = section.getBoundingClientRect().top + window.pageYOffset - (window.innerWidth * 0.1282);

    if (slide === 'VIDEO') {
      console.log(offset);
      window.scrollTo({
        top: offset + 40,
        behavior: 'smooth'
      });
    } else {
      window.scrollTo({
        top: offset,
        behavior: 'smooth'
      });
    }
  } else if (section && viewportType === 'isDesktopNarrow') {
    section.scrollIntoView({
      behavior: 'smooth'
    });
  } else {
    window.fullpage_api.moveTo(slide);
  }

  if (callback && typeof callback === 'function') {
    callback();
  }
};

export default onScrollHandler;