import React from 'react';
import imgBg from './assets/bg.svg';

const ScrollDown = (props) => {
	return (
		<div id='ScrollDown' className={props.show ? '' : 'hide'}>
			<img src={imgBg} alt='SCROLL' />
		</div>
	);
};

export default ScrollDown;