/**
 * 數字格式化，加上逗點
 * @param {number | string} number - 需要格式化的數字
 * @param {number} [decimalPoint] - 取到小數點後幾位
 * @returns {string} 格式化後的字串
 */
const numberFormat = ({
  number,
  decimalPoint = 2
}) => {
  // return !Number.isNaN(Number(number)) ? Number(number).toFixed(decimalPoint).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : '0.00';
  const parsedNumber = Number(number);
  if (!Number.isNaN(parsedNumber)) {
    const truncatedNumber = Math.floor(parsedNumber * Math.pow(10, decimalPoint)) / Math.pow(10, decimalPoint);
    return truncatedNumber.toFixed(decimalPoint).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }
  return '0.00';
};

export {
  numberFormat,
};