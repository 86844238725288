import React from "react";
import { createRoot } from "react-dom/client";
import "./i18n";
import App from "./App";
import "./styles/index.sass";
import { BrowserRouter } from "react-router-dom";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import VConsole from "vconsole";

ReactGA.initialize("G-KDZ8WS4G17");

TagManager.initialize({
  gtmId: "GTM-5MZS56H",
});

const container = document.getElementById("root");
const root = createRoot(container);

// 判斷目前環境是否為開發環境 或 url 包含 https://staging.llamaland.io/ 及 https://test.llamaland.io/
const urlHost = window.location.host;
const isDev =
  process.env.NODE_ENV === "development" ||
  urlHost.includes("localhost") ||
  urlHost.includes("test");

if (isDev) {
  const vConsole = new VConsole();
}

root.render(
  // 註解掉 StrictMode 防止 useEffect 運行兩次
  // <React.StrictMode>
  <BrowserRouter>
    <App />
  </BrowserRouter>
  // </React.StrictMode>
);
