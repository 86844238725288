import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enJson from "../i18n/langs/en.json";
import zhTwJson from "../i18n/langs/zh-tw.json";

// 語系設定
const resources = {
    en: {
        translation: enJson
    },
    'zh-TW': {
        translation: zhTwJson
    }
};

// 初始化
i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: window.localStorage.getItem('lang') || "en",
        // default
        fallbackLng: window.localStorage.getItem('lang') || 'en'
    });

export default i18n;