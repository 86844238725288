import React, { useMemo } from "react";
import WalletController from "../../controller/WalletController";
import imgBgSpin from "./assets/bg-spin.svg";
import onScrollHandler from "../../utils/scrollTo";
import PlayImg from "./assets/play-d.png";

const MintButton = (props) => {
  const {
    viewportType,
    freeMintStage, // Mint Button State 1.inactive 2.active 判斷解鎖數量是否大於 0(白單 - Free Mint)
    allowListStage, // Mint Button State 1.inactive 2.active 判斷解鎖數量是否大於 0(白單 - Allow List Mint)
    stage, // Mint Button State 1.inactive 2.active 判斷解鎖數量是否大於 0(公售)
    step,
    freeMintUnlockAmount, // 目前解鎖可 Mint 數量上限(白單 - Free Mint)
    allowListUnlockAmount, // 目前解鎖可 Mint 數量上限(白單 - Allow List Mint)
    unlockAmount, // 目前解鎖可 Mint 數量上限(公售)
    freeMintClaimedAmount, // 被 Mint 掉數量(白單 - Free Mint)
    allowListClaimedAmount, // 被 Mint 掉數量(白單 - Allow List Mint)
    claimedAmount, // 被 Mint 掉數量(公售)
    inFreeMintClaimedList, // 白單是否 Mint 過了(Free Mint)
    inAllowListClaimedList, // 白單是否 Mint 過了(Allow List Mint)
    hasFreeMintMerkleProof,
    hasAllowListMintMerkleProof,
    env,
    setDialogue,
    gameStep, // 是否是開放遊戲階段
    freeMintIndexActive, // 合約是否有啟用 Index(白單 - Free Mint)
    allowListIndexActive, // 合約是否有啟用 Index(白單 - Allow List)
  } = props;

  // 剩餘可以 Mint 總數(白單 - Free Mint)
  const totalMaxFreeWLClaimAmount = useMemo(() => {
    const propFreeMintUnlockAmount = isNaN(Number(freeMintUnlockAmount))
      ? 0
      : Number(freeMintUnlockAmount);
    const propFreeMintClaimedAmount = isNaN(Number(freeMintClaimedAmount))
      ? 0
      : Number(freeMintClaimedAmount);
    return propFreeMintUnlockAmount - propFreeMintClaimedAmount;
  }, [freeMintUnlockAmount, freeMintClaimedAmount]);

  // 剩餘可以 Mint 總數(白單 - Allow List Mint)
  const totalMaxWLClaimAmount = useMemo(() => {
    const propAllowListUnlockAmount = isNaN(Number(allowListUnlockAmount))
      ? 0
      : Number(allowListUnlockAmount);
    const propAllowListClaimedAmount = isNaN(Number(allowListClaimedAmount))
      ? 0
      : Number(allowListClaimedAmount);
    return propAllowListUnlockAmount - propAllowListClaimedAmount;
  }, [allowListUnlockAmount, allowListClaimedAmount]);

  // 剩餘可以 Mint 總數(公售)
  const totalMaxClaimAmount = useMemo(() => {
    const propUnlockAmount = isNaN(Number(unlockAmount))
      ? 0
      : Number(unlockAmount);
    const propClaimedAmount = isNaN(Number(claimedAmount))
      ? 0
      : Number(claimedAmount);
    return propUnlockAmount - propClaimedAmount;
  }, [unlockAmount, claimedAmount]);

  // 判斷是否顯示可以 Mint 的按鈕
  const availableShowMintBtn = useMemo(() => {
    switch (step) {
      // 需要檢查 1.駱馬數量還夠嗎 -> true, 反之 -> false
      // 其餘 false
      case "presale":
      case "opensale":
        if (
          ((freeMintIndexActive || allowListIndexActive) &&
            (hasFreeMintMerkleProof || hasAllowListMintMerkleProof) &&
            (!inAllowListClaimedList || !inFreeMintClaimedList) &&
            ((totalMaxFreeWLClaimAmount > 0 && freeMintStage === "active") ||
              (totalMaxWLClaimAmount > 0 && allowListStage === "active"))) ||
          (totalMaxClaimAmount > 0 && stage === "active")
        ) {
          return true;
        }
        return false;
      // 非空投階段或公售直接返回 false
      default:
        return false;
    }
  }, [
    step,
    freeMintIndexActive,
    allowListIndexActive,
    hasFreeMintMerkleProof,
    hasAllowListMintMerkleProof,
    inAllowListClaimedList,
    inFreeMintClaimedList,
    totalMaxFreeWLClaimAmount,
    freeMintStage,
    totalMaxWLClaimAmount,
    allowListStage,
    totalMaxClaimAmount,
    stage,
  ]);

  const stageClassName = useMemo(
    () => (availableShowMintBtn ? "active" : "inactive"),
    [availableShowMintBtn]
  );

  console.log(step, gameStep);

  return (
    <div id="MintButton">
      {availableShowMintBtn && !gameStep && (
        <img src={imgBgSpin} alt="" className="spin" />
      )}
      {gameStep ? (
        <>
          <img src={imgBgSpin} alt="" className="spin" />
          <img
            src={PlayImg}
            alt="playBtn"
            className="playBtn"
            onClick={() => onScrollHandler(viewportType, "PLAYENTRY")}
          />
        </>
      ) : (
        <a
          href="#"
          className={`content ${stageClassName} ${step ? step : ""}`}
          onClick={(e) => {
            e.preventDefault();
            if (
              !availableShowMintBtn ||
              !WalletController ||
              !WalletController.wallet ||
              !env ||
              !env.CHAIN_ID
            )
              return;

            WalletController.wallet
              .isConnected()
              .then((res) => {
                if (res) {
                  WalletController.sender.requestChain().then((userChainId) => {
                    if (userChainId === env.CHAIN_ID) {
                      onScrollHandler(viewportType, "MINT");
                    } else {
                      // 顯示提示切換網彈窗
                      setDialogue("switch");
                    }
                  });
                } else {
                  onScrollHandler(viewportType, "MINT");
                }
              })
              .catch((err) => {
                if (step === "presale" || step === "opensale") {
                  onScrollHandler(viewportType, "MINT");
                }
                console.log(err);
              });
          }}
        />
      )}
    </div>
  );
};

export default MintButton;
