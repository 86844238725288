import { createGlobalState } from 'react-use';

const useAlert = createGlobalState({
	show: false,
	type: '', // success, failure, info
	title: '',
	message: '',
	callback: () => {},
	showCloseBtn: false
});

export default useAlert;
