import React, { useState, useMemo, useCallback, useEffect } from "react";
import useLoading from "../../store/useLoading";
import useAlert from "../../store/useAlert";
import imgButtonInventory_en from "./assets/inventory.png";
import imgButtoninventoryDisabled_en from "./assets/inventory-B.png";
import {
  PostOpenSaleClaimRequest,
  PostFreeMintClaimRequest,
  PostAllowListClaimRequest,
} from "../../request";
import Big from "big.js";
import axios from "axios";
import useENV from "../../store/useENV";
import WalletController from "../../controller/WalletController";
import { useTranslation } from "react-i18next";
import imgButtonInventory_zhTW from "./assets/inventory-zh-TW.png";
import imgButtoninventoryDisabled_zhTW from "./assets/inventory-B-zh-TW.png";
import closeIcon from "./assets/close-icon.png";
import cyberLily from "./assets/cyberLily.png";

import freeMint from "./assets/button-free-mint.png";
import freeMintDisabled from "./assets/button-free-mint-disabled.png";
import allowListMint from "./assets/button-allow-list-mint.png";
import allowListMintDisabled from "./assets/button-allow-list-mint-disabled.png";
import publicMint from "./assets/button-public-mint.png";
import publicMintDisabled from "./assets/button-public-mint-disabled.png";

const Mint = (props) => {
  const { t, i18n } = useTranslation();
  const env = useENV()[0];
  const {
    step,
    freeMintStage,
    allowListStage,
    stage,
    totalSupply,
    freeMintMaxClaim,
    allowListMaxClaim,
    maxClaim,
    freeMintPrice = 0,
    allowListMintPrice = 0,
    mintPrice = 0,
    freeMintUnlockAmount,
    allowListUnlockAmount,
    unlockAmount,
    freeMintClaimedAmount,
    allowListClaimedAmount,
    claimedAmount,
    inFreeMintClaimedList,
    inAllowListClaimedList,
    updateContract, // 更新合約資訊
    hasFreeMintMerkleProof, // 是否有 MerkleProof(Free Mint)
    hasAllowListMerkleProof, // 是否有 MerkleProof(Allow List)
    setDialogue,
    navAccount,
    freeMintIndexActive, // 合約是否有啟用 Index(白單 - Free Mint)
    allowListIndexActive, // 合約是否有啟用 Index(白單 - Allow List)
  } = props;
  const [count, setCount] = useState(1);
  const setAlert = useAlert()[1];
  const setLoading = useLoading()[1];
  const setLang = useState(i18n.language)[1];
  const [mintDialogType, setMintDialogType] = useState(null); // 1. Free Mint 2. Allow List Mint 3. Public Mint
  const [mintDialogVisible, setMintDialogVisible] = useState(false);

  useEffect(() => {
    setLang(i18n.language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const imgButtonInventory = useMemo(() => {
    if (i18n.language === "en") {
      return imgButtonInventory_en;
    } else {
      return imgButtonInventory_zhTW;
    }
  }, [i18n.language]);

  const imgButtoninventoryDisabled = useMemo(() => {
    if (i18n.language === "en") {
      return imgButtoninventoryDisabled_en;
    } else {
      return imgButtoninventoryDisabled_zhTW;
    }
  }, [i18n.language]);

  // 按鈕新增減少邏輯判斷
  const decrease = () => {
    if (count !== 1) setCount(count - 1);
  };

  const increase = () => {
    switch (mintDialogType) {
      case "Free Mint":
        if (count < freeMintMaxClaim) setCount(count + 1);
        break;
      case "Allow List Mint":
        if (count < allowListMaxClaim) setCount(count + 1);
        break;
      case "Public Mint":
        if (count < maxClaim) setCount(count + 1);
        break;
      default:
        break;
    }
  };

  // 增加減少按鈕的 Class Name
  const inCreaseClassName = useMemo(() => {
    switch (mintDialogType) {
      case "Free Mint":
        if (count < freeMintMaxClaim) return "increase";
        return "increase disabled";
      case "Allow List Mint":
        if (count < allowListMaxClaim) return "increase";
        return "increase disabled";
      case "Public Mint":
        if (count < maxClaim) return "increase";
        return "increase disabled";
      default:
        return "increase disabled";
    }
  }, [allowListMaxClaim, count, freeMintMaxClaim, maxClaim, mintDialogType]);

  // 剩餘可以 Mint 總數(白單 - Free Mint)
  const totalMaxFreeWLClaimAmount = useMemo(() => {
    const propFreeMintUnlockAmount = isNaN(Number(freeMintUnlockAmount))
      ? 0
      : Number(freeMintUnlockAmount);
    const propFreeMintClaimedAmount = isNaN(Number(freeMintClaimedAmount))
      ? 0
      : Number(freeMintClaimedAmount);
    return propFreeMintUnlockAmount - propFreeMintClaimedAmount;
  }, [freeMintUnlockAmount, freeMintClaimedAmount]);

  // 剩餘可以 Mint 總數(白單 - Allow List)
  const totalMaxWLClaimAmount = useMemo(() => {
    const propAllowListUnlockAmount = isNaN(Number(allowListUnlockAmount))
      ? 0
      : Number(allowListUnlockAmount);
    const propAllowListClaimedAmount = isNaN(Number(allowListClaimedAmount))
      ? 0
      : Number(allowListClaimedAmount);
    return propAllowListUnlockAmount - propAllowListClaimedAmount;
  }, [allowListUnlockAmount, allowListClaimedAmount]);

  // 剩餘可以 Mint 總數(公售)
  const totalMaxClaimAmount = useMemo(() => {
    const propUnlockAmount = isNaN(Number(unlockAmount))
      ? 0
      : Number(unlockAmount);
    const propClaimedAmount = isNaN(Number(claimedAmount))
      ? 0
      : Number(claimedAmount);
    return propUnlockAmount - propClaimedAmount;
  }, [unlockAmount, claimedAmount]);

  // 白單價格(Free Mint)
  const freeMintClaimValueAmount = useMemo(() => {
    if (!freeMintPrice) return 0;
    return new Big(freeMintPrice).times(count).valueOf();
  }, [count, freeMintPrice]);

  const freeMintClaimValueAmountToWei = useMemo(() => {
    if (!freeMintPrice) return 0;
    return window["Web3"].utils.toWei(freeMintClaimValueAmount);
  }, [freeMintClaimValueAmount, freeMintPrice]);

  // 白單價格(Allow List Mint)
  const allowListClaimValueAmount = useMemo(() => {
    if (!allowListMintPrice) return 0;
    return new Big(allowListMintPrice).times(count).valueOf();
  }, [count, allowListMintPrice]);

  const allowListClaimValueAmountToWei = useMemo(() => {
    if (!allowListMintPrice) return 0;
    return window["Web3"].utils.toWei(allowListClaimValueAmount);
  }, [allowListClaimValueAmount, allowListMintPrice]);

  // 公售價格
  const claimValueAmount = useMemo(() => {
    if (!mintPrice) return 0;
    return new Big(mintPrice).times(count).valueOf();
  }, [count, mintPrice]);

  const claimValueAmountToWei = useMemo(() => {
    if (!mintPrice) return 0;
    return window["Web3"].utils.toWei(claimValueAmount);
  }, [claimValueAmount, mintPrice]);

  // 顯示在彈窗上的價格
  const dialogMintPrice = useMemo(() => {
    switch (mintDialogType) {
      case "Free Mint":
        return freeMintClaimValueAmount;
      case "Allow List Mint":
        return allowListClaimValueAmount;
      case "Public Mint":
        return claimValueAmount;
      default:
        return 0;
    }
  }, [
    allowListClaimValueAmount,
    claimValueAmount,
    freeMintClaimValueAmount,
    mintDialogType,
  ]);

  const onErrorShow = useCallback((e, callback = () => {}) => {
    setAlert({
      show: true,
      type: "failure",
      title: "Oops!",
      message: e,
      callback() {
        callback();
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 判斷是否可以顯示 Inventory 連結到 Market Place, 須符合 1. 錢包裡有駱馬 2. 階段不得為 comingsoon
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const availableShowInventoryBtn = useMemo(
    () =>
      window.account !== "" && window.account && step !== "comingsoon"
        ? true
        : false,
    [step, window.account]
  );

  // 判斷是否可以顯示 Free Mint 的按鈕
  const availableShowFreeMintBtn = useMemo(() => {
    if (!WalletController || !WalletController.sender?.detectProvider())
      return false;

    // 空投階段需要檢查 1.是否為白單 2.駱馬數量還夠嗎 3.是否 Mint 過了 -> true
    if (
      step &&
      (step === "presale" || step === "opensale") &&
      freeMintIndexActive &&
      hasFreeMintMerkleProof &&
      !inFreeMintClaimedList &&
      freeMintStage === "active" &&
      totalMaxFreeWLClaimAmount > 0 &&
      navAccount
    ) {
      return true;
    }
    return false;
  }, [
    freeMintIndexActive,
    freeMintStage,
    hasFreeMintMerkleProof,
    inFreeMintClaimedList,
    navAccount,
    step,
    totalMaxFreeWLClaimAmount,
  ]);

  // 判斷是否顯示可以 Mint 的按鈕(白單按鈕判斷)
  const availableShowAllowListMintBtn = useMemo(() => {
    if (!WalletController || !WalletController.sender?.detectProvider())
      return false;

    // 空投階段需要檢查 1.是否為白單 2.駱馬數量還夠嗎 3.是否 Mint 過了 -> true
    if (
      step &&
      (step === "presale" || step === "opensale") &&
      allowListIndexActive &&
      hasAllowListMerkleProof &&
      !inAllowListClaimedList &&
      allowListStage === "active" &&
      totalMaxWLClaimAmount > 0 &&
      navAccount
    ) {
      return true;
    }
    return false;
  }, [
    step,
    allowListIndexActive,
    hasAllowListMerkleProof,
    inAllowListClaimedList,
    allowListStage,
    totalMaxWLClaimAmount,
    navAccount,
  ]);

  // 判斷是否顯示可以 Mint 的按鈕(公售按鈕判斷)
  const availableShowMintBtn = useMemo(() => {
    if (
      !WalletController ||
      !WalletController.sender?.detectProvider() ||
      step !== "opensale"
    )
      return false;

    // 公售階段需要檢查 1.駱馬數量還夠嗎 -> true, 反之 -> false
    if (
      step &&
      step === "opensale" &&
      totalMaxClaimAmount > 0 &&
      stage === "active" &&
      navAccount
    ) {
      return true;
    }
    return false;
  }, [step, totalMaxClaimAmount, stage, navAccount]);

  // 重置 Mint 的邏輯
  const resetMint = () => {
    setMintDialogVisible(false);
    setMintDialogType(null);
    setCount(1);
  };

  useEffect(() => {
    resetMint();
  }, [navAccount]);

  // 白單 Free Mint Btn Click
  const freeMintHandler = async () => {
    if (!WalletController || !env || !env.CHAIN_ID) return;

    if (!WalletController.sender.detectProvider()) {
      setDialogue("install");
      return;
    }

    WalletController.wallet.isConnected().then((res) => {
      if (res) {
        WalletController.sender.requestChain().then((userChainId) => {
          if (userChainId === env.CHAIN_ID) {
            if (step === "comingsoon") {
              return;
            }

            if (availableShowFreeMintBtn) {
              setMintDialogType("Free Mint");
              setMintDialogVisible(true);
            }
          } else {
            // 顯示提示切換網彈窗
            setDialogue("switch");
          }
        });
      } else {
        setDialogue("connect");
      }
    });
  };

  // 白單鑄造(Free Mint)
  const startFreeMint = async () => {
    if (!env.API_ROUTER) return;

    if (count > totalMaxFreeWLClaimAmount) {
      onErrorShow(t("MintSection.There are not enough Llamas left"));
      return;
    }

    if (count > freeMintMaxClaim) {
      onErrorShow(`You can only mint ${freeMintMaxClaim} NFT at a time!!`);
      return;
    }

    setLoading(true);

    const account = await WalletController.getAccount();

    // 再次取得 MerkleProof
    axios
      .get(`${env.API_ROUTER}/api/get-merkle-tree-proof`, {
        params: {
          key: "FREE_MINT",
          account: account,
        },
      })
      .then((res) => {
        const { data: getMerkeTreeProofData, status } = res;
        if (
          status === 200 &&
          getMerkeTreeProofData &&
          getMerkeTreeProofData.proof &&
          getMerkeTreeProofData.proof.length > 0
        ) {
          WalletController.send(
            new PostFreeMintClaimRequest(
              getMerkeTreeProofData.proof,
              count.toString(),
              freeMintClaimValueAmountToWei,
              account,
              (e) => {
                setLoading(false);
                setAlert({
                  show: true,
                  type: "info",
                  title: t("MintSection.Waiting for transition"),
                  message: t("MintSection.Please do not refresh this page"),
                });
              },
              (e) => {
                setLoading(false);
                onErrorShow(e.toString() ?? "onReject");
              },
              (e) => {
                setLoading(false);
                onErrorShow(e.toString() ?? "onError");
              },
              (e) => {
                setLoading(false);
                if (e && e.status) {
                  setAlert({
                    show: true,
                    type: "success",
                    title: t("MintSection.Success"),
                    message: t("MintSection.Mint Success"),
                    // 更新合約資訊
                    callback() {
                      updateContract();
                      resetMint();
                    },
                  });
                } else {
                  onErrorShow(
                    t("MintSection.Mint Fails, please try again"),
                    () => {
                      updateContract();
                      resetMint();
                    }
                  );
                }
              },
              () => {
                setLoading(false);
                onErrorShow(
                  t("MintSection.Mint Fails, please try again"),
                  () => {
                    updateContract();
                    resetMint();
                  }
                );
              }
            )
          );
        } else {
          setLoading(false);
          onErrorShow(t("MintSection.You are not in the white list"));
        }
      })
      .catch((err) => {
        const { data: errData } = err.response;
        setLoading(false);
        onErrorShow(errData.error ?? "onError");
      });
  };

  // 白單 Allow List Btn Click
  const allowListMintHandler = async () => {
    if (!WalletController || !env || !env.CHAIN_ID) return;

    if (!WalletController.sender.detectProvider()) {
      setDialogue("install");
      return;
    }

    WalletController.wallet.isConnected().then((res) => {
      if (res) {
        WalletController.sender.requestChain().then((userChainId) => {
          if (userChainId === env.CHAIN_ID) {
            // 空頭階段未開放或結束都需要 return 掉
            if (step === "comingsoon" || step === "end") {
              return;
            }

            if (availableShowAllowListMintBtn) {
              setMintDialogType("Allow List Mint");
              setMintDialogVisible(true);
            }
          } else {
            // 顯示提示切換網彈窗
            setDialogue("switch");
          }
        });
      } else {
        setDialogue("connect");
      }
    });
  };

  // 白單鑄造(Allow List Mint)
  const startAllowListWLMint = async () => {
    if (!env.API_ROUTER) return;

    if (count > totalMaxWLClaimAmount) {
      onErrorShow(t("MintSection.There are not enough Llamas left"));
      return;
    }

    if (count > allowListMaxClaim) {
      onErrorShow(`You can only mint ${allowListMaxClaim} NFT at a time!!`);
      return;
    }

    setLoading(true);

    const account = await WalletController.getAccount();

    // 再次取得 MerkleProof
    axios
      .get(`${env.API_ROUTER}/api/get-merkle-tree-proof`, {
        params: {
          key: "ALLOW_LIST",
          account: account,
        },
      })
      .then((res) => {
        const { data: getMerkeTreeProofData, status } = res;
        if (
          status === 200 &&
          getMerkeTreeProofData &&
          getMerkeTreeProofData.proof &&
          getMerkeTreeProofData.proof.length > 0
        ) {
          WalletController.send(
            new PostAllowListClaimRequest(
              getMerkeTreeProofData.proof,
              count.toString(),
              allowListClaimValueAmountToWei,
              account,
              (e) => {
                setLoading(false);
                setAlert({
                  show: true,
                  type: "info",
                  title: t("MintSection.Waiting for transition"),
                  message: t("MintSection.Please do not refresh this page"),
                });
              },
              (e) => {
                setLoading(false);
                onErrorShow(e.toString() ?? "onReject");
              },
              (e) => {
                setLoading(false);
                onErrorShow(e.toString() ?? "onError");
              },
              (e) => {
                setLoading(false);
                if (e && e.status) {
                  setAlert({
                    show: true,
                    type: "success",
                    title: t("MintSection.Success"),
                    message: t("MintSection.Mint Success"),
                    // 更新合約資訊
                    callback() {
                      updateContract();
                      resetMint();
                    },
                  });
                } else {
                  onErrorShow(
                    t("MintSection.Mint Fails, please try again"),
                    () => {
                      updateContract();
                      resetMint();
                    }
                  );
                }
              },
              () => {
                setLoading(false);
                onErrorShow(
                  t("MintSection.Mint Fails, please try again"),
                  () => {
                    updateContract();
                    resetMint();
                  }
                );
              }
            )
          );
        } else {
          setLoading(false);
          onErrorShow(t("MintSection.You are not in the white list"));
        }
      })
      .catch((err) => {
        const { data: errData } = err.response;
        setLoading(false);
        onErrorShow(errData.error ?? "onError");
      });
  };

  // 公售 Public Mint Btn Click
  const publicMintHandler = async () => {
    if (!WalletController || !env || !env.CHAIN_ID) return;

    if (!WalletController.sender.detectProvider()) {
      setDialogue("install");
      return;
    }

    WalletController.wallet.isConnected().then((res) => {
      if (res) {
        WalletController.sender.requestChain().then((userChainId) => {
          if (userChainId === env.CHAIN_ID) {
            // 不是公售階段，未開放或結束都需要 return 掉
            if (step === "comingsoon" || step === "presale" || step === "end") {
              return;
            }

            if (availableShowMintBtn) {
              setMintDialogType("Public Mint");
              setMintDialogVisible(true);
            }
          } else {
            // 顯示提示切換網彈窗
            setDialogue("switch");
          }
        });
      } else {
        setDialogue("connect");
      }
    });
  };

  // 公售鑄造(Public Mint)
  const startPublicMint = async () => {
    if (!env.API_ROUTER) return;

    if (count > totalMaxClaimAmount) {
      onErrorShow(t("MintSection.There are not enough Llamas left"));
      return;
    }

    setLoading(true);

    // 公售階段
    if (step === "opensale") {
      const account = await WalletController.getAccount();

      await WalletController.send(
        new PostOpenSaleClaimRequest(
          count.toString(),
          claimValueAmountToWei,
          account,
          (e) => {
            setLoading(false);
            setAlert({
              show: true,
              type: "info",
              title: t("MintSection.Waiting for transition"),
              message: t("MintSection.Please do not refresh this page"),
            });
          },
          (e) => {
            setLoading(false);
            onErrorShow(e.toString() ?? "onReject");
          },
          (e) => {
            setLoading(false);
            onErrorShow(e.toString() ?? "onError");
          },
          (e) => {
            setLoading(false);
            if (e && e.status) {
              setAlert({
                show: true,
                type: "success",
                title: t("MintSection.Success"),
                message: t("MintSection.Mint Success"),
                // 更新合約資訊
                callback() {
                  updateContract();
                  resetMint();
                },
              });
            } else {
              onErrorShow(t("MintSection.Mint Fails, please try again"), () => {
                updateContract();
                resetMint();
              });
            }
          },
          () => {
            setLoading(false);
            onErrorShow(t("MintSection.Mint Fails, please try again"), () => {
              updateContract();
              resetMint();
            });
          }
        )
      );
    }
  };

  return (
    <section id="MINTsection" className="Mint section">
      <div className="section-content">
        <div className="info-wrap">
          <div className="info-group">
            <div className="t1">AVAILABLE</div>
            <div className="t2">{totalSupply}</div>
            <div className="t3">/ 8888</div>
          </div>
        </div>

        <div className="mint-button-area">
          <img
            alt="free mint"
            src={availableShowFreeMintBtn ? freeMint : freeMintDisabled}
            className={
              !availableShowFreeMintBtn
                ? "cursor-not-allowed"
                : "cursor-pointer"
            }
            onClick={() => freeMintHandler()}
          />
          <img
            src={
              availableShowAllowListMintBtn
                ? allowListMint
                : allowListMintDisabled
            }
            alt="allow list mint"
            className={
              !availableShowAllowListMintBtn
                ? "cursor-not-allowed"
                : "cursor-pointer"
            }
            onClick={() => allowListMintHandler()}
          />
          <img
            src={availableShowMintBtn ? publicMint : publicMintDisabled}
            alt="public mint"
            className={
              !availableShowMintBtn ? "cursor-not-allowed" : "cursor-pointer"
            }
            onClick={() => publicMintHandler()}
          />
          {availableShowInventoryBtn ? (
            <img
              src={imgButtonInventory}
              alt="Inventory Btn"
              className="desktop market-place cursor-pointer"
              onClick={() => window.open(env.ELEMENT_ACCOUNT_URL, "_blank")}
            />
          ) : (
            <img
              src={imgButtoninventoryDisabled}
              alt="Inventory Disabled Btn"
              className="desktop market-place market-place-disabled cursor-not-allowed"
              onClick={() => {
                if (!window.account || window.account === "") {
                  setDialogue("connect");
                  return;
                }

                if (step === "comingsoon") {
                  return;
                }
              }}
            />
          )}
        </div>
      </div>

      {/* Mint Dialog */}
      <div
        className={
          mintDialogVisible ? "mintDialog mintDialog-show" : "mintDialog"
        }
      >
        {/* blur */}
        <div className="mintDialog-blur" />
        <div className="mintDialog-container">
          {/* Mint Dialog Header */}
          <div className="mintDialog-header">
            <img className="cyberLily-icon" src={cyberLily} alt={cyberLily} />
            <div className="mintDialog-header-title" />
            {/* Close Btn */}
            <div className="mintDialog-header-title-close">
              <img
                className="close-icon"
                src={closeIcon}
                alt="Close"
                onClick={() => {
                  setMintDialogVisible(false);
                  setMintDialogType(null);
                  setCount(1);
                }}
              />
            </div>
          </div>

          {/* Mint Dialog Content */}
          <div className="mintDialog-content">
            <div className="counter-wrap">
              <div
                className={
                  count === 1 ? "button decrease disabled" : "button decrease"
                }
                onClick={decrease}
              />
              <div className="count">{count}</div>
              <div
                className={`${inCreaseClassName} button`}
                onClick={increase}
              />
              <div className="mintPrice">
                <div className="t1">Total</div>
                <div className="t2">{dialogMintPrice}</div>
                <div className="t3">BNB</div>
              </div>
              <div className="mintBtn">
                {mintDialogType === "Free Mint" && (
                  <img
                    alt="free mint"
                    src={
                      availableShowFreeMintBtn &&
                      count <= totalMaxFreeWLClaimAmount
                        ? freeMint
                        : freeMintDisabled
                    }
                    className={
                      !availableShowFreeMintBtn ||
                      count > totalMaxFreeWLClaimAmount
                        ? "cursor-not-allowed mintBtn-img"
                        : "cursor-pointer mintBtn-img"
                    }
                    onClick={() => startFreeMint()}
                  />
                )}
                {mintDialogType === "Allow List Mint" && (
                  <img
                    alt="allow list mint"
                    src={
                      availableShowAllowListMintBtn &&
                      count <= totalMaxWLClaimAmount
                        ? allowListMint
                        : allowListMintDisabled
                    }
                    className={
                      !availableShowAllowListMintBtn ||
                      count > totalMaxWLClaimAmount
                        ? "cursor-not-allowed mintBtn-img"
                        : "cursor-pointer mintBtn-img"
                    }
                    onClick={() => startAllowListWLMint()}
                  />
                )}
                {mintDialogType === "Public Mint" && (
                  <img
                    alt="public mint"
                    src={
                      availableShowMintBtn && count <= totalMaxClaimAmount
                        ? publicMint
                        : publicMintDisabled
                    }
                    className={
                      !availableShowMintBtn || count > totalMaxClaimAmount
                        ? "cursor-not-allowed mintBtn-img"
                        : "cursor-pointer mintBtn-img"
                    }
                    onClick={() => startPublicMint()}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Mint;
