import React from "react";
import ReactPlayer from "react-player";
import { EXTERNAL_LINK } from "../../configs/link";

const VideoArea = () => {
  const { PROMOTIONAL_YOUTUBE_VIDEO_ID } = EXTERNAL_LINK;
  return (
    <section id="VIDEOsection" className="VideoArea section">
      <div className="videoContent">
        <div className="videoContent__player">
          <ReactPlayer
            url={`https://www.youtube.com/watch?v=${PROMOTIONAL_YOUTUBE_VIDEO_ID}`}
            width="100%"
            height="100%"
            controls
          />
        </div>
      </div>
    </section>
  );
};

export default VideoArea;
