import { useEffect, useRef } from "react";

const CanvasTextImg = ({
  width = 500,
  height = 500,
  imgUrl = '',
  text = ''
}) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    canvas.width = width;
    canvas.height = height;

    // 加載圖片
    const img = new Image();
    img.onload = () => {
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      const textX = canvas.offsetWidth < 480 ? 70 : 70;
      const textY = canvas.offsetWidth < 460 ? 42 : 50;

      // 添加文本
      ctx.font = 'bold 14px italic Montserrat';
      ctx.letterSpacing = '1px';
      ctx.fillStyle = 'white';
      ctx.fillText(text, textX, textY);
    };
    img.src = imgUrl;
  }, [height, imgUrl, text, width]);

  return (
    <div>
      <canvas
        ref={canvasRef}
        style={{
          width: '100%',
          height: '100%'
        }}
      />
    </div>
  );
};

export default CanvasTextImg;