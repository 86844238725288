import { ContractRequest, Web3Role } from "web3wallet/dist/web3wallet";

export class PostOpenSaleClaimRequest extends ContractRequest {
  constructor(
    amount,
    value,
    account,
    confirmCallback,
    rejectCallback,
    errorCallback,
    thenCallback,
    catchCallback
  ) {
    super(
      "LlamaLandPlayer",
      "claim",
      Web3Role.Sender,
      confirmCallback,
      rejectCallback,
      errorCallback,
      thenCallback,
      catchCallback
    );

    this.amount = amount;
    this.value = value;
    this.account = account;
    this.thenCallback = thenCallback;
    this.catchCallback = catchCallback;
  }

  send(contract) {
    const method = this.getMethod();
    contract.methods[method](this.amount)
      .send(
        {
          from: this.account,
          value: this.value,
          type: 0,
        },
        this.handleResponse.bind(this)
      )
      .then(this.thenCallback)
      .catch(() => {
        this.handleError.bind(this);
        this.catchCallback();
      });
  }
}
