import { ContractRequest, Web3Role } from "web3wallet/dist/web3wallet";

const Web3 = window["Web3"];

export class PostLHCWormholeTossRequest extends ContractRequest {
  constructor(
    value,
    account,
    confirmCallback,
    rejectCallback,
    errorCallback,
    thenCallback,
    catchCallback
  ) {
    super(
      "Wormhole",
      "toss",
      Web3Role.Sender,
      confirmCallback,
      rejectCallback,
      errorCallback
    );

    this.value = value;
    this.account = account;
    this.thenCallback = thenCallback;
    this.catchCallback = catchCallback;
  }

  send(contract) {
    console.log(
      "this.value",
      this.value,
      Web3.utils.toWei(this.value.toString())
    );
    const method = this.getMethod();
    contract.methods[method](Web3.utils.toWei(this.value.toString()))
      .send(
        {
          from: this.account,
          type: 0,
        },
        this.handleResponse.bind(this)
      )
      .then(this.thenCallback)
      .catch((e) => {
        this.handleError.bind(this);
        this.catchCallback(e);
      });
  }
}
