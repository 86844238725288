import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const Roadmap = (props) => {
  const { t } = useTranslation();
  const [progress] = useState(3);
  const [startAnimation, setStartAnimation] = useState("");

  useEffect(() => {
    window.addEventListener("hashchange", () => {
      if (window.location.hash !== "#ROADMAP") {
        setStartAnimation("");
      } else {
        setStartAnimation("animate");
      }
    });
  }, []);

  return (
    <section id="ROADMAPsection" className="Roadmap section">
      <div className="section-content">
        <div className="title">ROADMAP</div>

        <div className="mobile-flex">
          <div className="progress-wrap">
            <div
              className={`milestone m1 ${
                progress >= 1 ? "active" : ""
              } ${startAnimation}`}
            >
              <div className={`phase ${progress === 1 ? "active" : ""}`}>
                PHASE<span> 1</span>
              </div>

              {props.viewportType === "isMobile" && (
                <ul className="list">
                  <li className="list__item">
                    {t("RoadMapSection.Project concept")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Game style、character style")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.NFT design")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Building website")}{" "}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.$LHC token contract")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Optimize mint process")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Token allocation")}{" "}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Game ecosystem structure")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Whitepaper")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Game math design")}
                  </li>
                </ul>
              )}
            </div>

            <div
              className={`line l1 ${
                progress >= 2 ? "active" : ""
              } ${startAnimation}`}
            >
              <div className="active-item"></div>
            </div>

            <div
              className={`milestone m2 ${
                progress >= 2 ? "active" : ""
              } ${startAnimation}`}
            >
              <div className={`phase ${progress === 2 ? "active" : ""}`}>
                PHASE<span> 2</span>
              </div>

              {props.viewportType === "isMobile" && (
                <ul className="list">
                  <li className="list__item">
                    {t("RoadMapSection.Game teaser")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.CyberLily develop")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Website staging")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Game contract")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.LHC contract")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Llama contract")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Relic contract")}
                  </li>
                </ul>
              )}
            </div>

            <div
              className={`line l2 ${
                progress >= 3 ? "active" : ""
              } ${startAnimation}`}
            >
              <div className="active-item"></div>
            </div>

            <div
              className={`milestone m3 ${
                progress >= 3 ? "active" : ""
              } ${startAnimation}`}
            >
              <div className={`phase ${progress === 3 ? "active" : ""}`}>
                PHASE<span> 3</span>
              </div>

              {props.viewportType === "isMobile" && (
                <ul className="list">
                  <li className="list__item">
                    {t("RoadMapSection.Game shop")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.collider building")}{" "}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Game hall")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Backpack system")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.History log")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Ranking system")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Game backstage")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.PVP environment")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Game music, sound effect")}
                  </li>
                  <li className="list__item">{t("RoadMapSection.Game VFX")}</li>
                  <li className="list__item">
                    {t("RoadMapSection.Social media channel")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.LAUNCHPAD")}
                  </li>
                </ul>
              )}
            </div>

            <div
              className={`line l3 ${
                progress >= 4 ? "active" : ""
              } ${startAnimation}`}
            >
              <div className="active-item"></div>
            </div>

            <div
              className={`milestone m4 ${
                progress >= 4 ? "active" : ""
              } ${startAnimation}`}
            >
              <div className={`phase ${progress === 4 ? "active" : ""}`}>
                PHASE<span> 4</span>
              </div>

              {props.viewportType === "isMobile" && (
                <ul className="list">
                  <li className="list__item">{t("RoadMapSection.Arena")}</li>
                  <li className="list__item">
                    {t("RoadMapSection.Seasons, event")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Sumo game")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Golf game")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Javelin throw game")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Soapbox race game")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Beauty pageant game")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Push and pull game")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Bowling game")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Race game")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.$LHC Token IEO")}
                  </li>
                </ul>
              )}
            </div>

            <div
              className={`line l4 ${
                progress >= 5 ? "active" : ""
              } ${startAnimation}`}
            >
              <div className="active-item"></div>
            </div>

            <div
              className={`milestone m5 ${
                progress >= 5 ? "active" : ""
              } ${startAnimation}`}
            >
              <div className={`phase ${progress === 5 ? "active" : ""}`}>
                PHASE<span> 5</span>
              </div>

              {props.viewportType === "isMobile" && (
                <ul className="list">
                  <li className="list__item">
                    {t("RoadMapSection.Mobile platform")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Seeking collaboration")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Multiverse")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Roguelike")}
                  </li>
                  <li className="list__item">{t("RoadMapSection.Summon")}</li>
                  <li className="list__item">{t("RoadMapSection.Fusion")}</li>
                </ul>
              )}
            </div>
          </div>

          {props.viewportType !== "isMobile" && (
            <div className="milestones">
              <div className="item">
                <ul className="list">
                  <li className="list__item">
                    {t("RoadMapSection.Project concept")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Game style、character style")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.NFT design")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Building website")}{" "}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.$LHC token contract")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Optimize mint process")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Token allocation")}{" "}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Game ecosystem structure")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Whitepaper")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Game math design")}
                  </li>
                </ul>
              </div>

              <div className="item">
                <ul className="list">
                  <li className="list__item">
                    {t("RoadMapSection.Game teaser")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.CyberLily develop")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Website staging")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Game contract")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.LHC contract")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Llama contract")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Relic contract")}
                  </li>
                </ul>
              </div>

              <div className="item">
                <ul className="list">
                  <li className="list__item">
                    {t("RoadMapSection.Game shop")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.collider building")}{" "}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Game hall")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Backpack system")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.History log")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Ranking system")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Game backstage")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.PVP environment")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Game music, sound effect")}
                  </li>
                  <li className="list__item">{t("RoadMapSection.Game VFX")}</li>
                  <li className="list__item">
                    {t("RoadMapSection.Social media channel")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.LAUNCHPAD")}
                  </li>
                </ul>
              </div>

              <div className="item">
                <ul className="list">
                  <li className="list__item">{t("RoadMapSection.Arena")}</li>
                  <li className="list__item">
                    {t("RoadMapSection.Seasons, event")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Sumo game")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Golf game")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Javelin throw game")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Soapbox race game")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Beauty pageant game")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Push and pull game")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Bowling game")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Race game")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.$LHC Token IEO")}
                  </li>
                </ul>
              </div>

              <div className="item">
                <ul className="list">
                  <li className="list__item">
                    {t("RoadMapSection.Mobile platform")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Seeking collaboration")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Multiverse")}
                  </li>
                  <li className="list__item">
                    {t("RoadMapSection.Roguelike")}
                  </li>
                  <li className="list__item">{t("RoadMapSection.Summon")}</li>
                  <li className="list__item">{t("RoadMapSection.Fusion")}</li>
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Roadmap;
