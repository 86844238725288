import React from "react";
import imgLogo from "./assets/logo-min.png";
import onScrollHandler from "../../utils/scrollTo";
import imgLinkElement from "./assets/imgLinkElement.png";
import imgLinkElementRelic from "./assets/imgLinkRelic.png";
import imgLink3 from "./assets/link3.svg";
import imgLink4 from "./assets/link4.svg";
import imgLink5 from "./assets/link5.svg";
import imgLinkElementHover from "./assets/imgLinkElementHover.png";
import imgLinkElementRelicHover from "./assets/imgLinkRelicHover.png";
import imgLink3Hover from "./assets/link3-hover.svg";
import imgLink4Hover from "./assets/link4-hover.svg";
import imgLink5Hover from "./assets/link5-hover.svg";
import { EXTERNAL_LINK } from "../../configs/link";
import { useTranslation } from "react-i18next";

const Footer = (props) => {
  const { ZH_TW_WHITE_PAPER, WHITE_PAPER, DISCORD, TWITTER } = EXTERNAL_LINK;
  const { i18n } = useTranslation();
  const { viewportType, env } = props;

  return (
    <div className="Footer section fp-auto-height" id="FOOTERsection">
      <div className="section-content">
        <div className="nav landing" data-menuanchor="LANDING">
          <a onClick={() => onScrollHandler(viewportType, "LANDING")}>
            <img src={imgLogo} alt="LLAMA LAND logo" className="logo" />
          </a>
        </div>

        <div className="nav-groups">
          <div className="nav-group">
            <div className="heading">Contact Us</div>

            <div className="nav">
              <a href="mailto:support@llamaland.io">support@llamaland.io</a>
            </div>
          </div>

          <div className="nav-group">
            <div className="heading">About Llama Land GameFi</div>

            <div className="navs">
              <div className="left">
                <div className="nav">
                  <a href="#NFT">NFT</a>
                </div>

                <div className="nav" data-menuanchor="GAME">
                  <a href="#GAME">GAME</a>
                </div>

                <div className="nav" data-menuanchor="LHC">
                  <a href="#LHC">$LHC</a>
                </div>
              </div>

              <div className="right">
                <div className="nav" data-menuanchor="TEAM">
                  <a href="#TEAM">TEAM</a>
                </div>

                <div className="nav" data-menuanchor="ROADMAP">
                  <a href="#ROADMAP">ROADMAP</a>
                </div>
              </div>
            </div>
          </div>

          <div className="nav-group">
            <div className="heading">FOLLOW Us</div>

            <div className="links">
              <a
                href={env.ELEMENT_COLLECTION_URL || "#"}
                target="_blank"
                rel="noreferrer noopener"
              >
                <img src={imgLinkElement} alt="element" className="logo" />
                <img
                  src={imgLinkElementHover}
                  alt="element hover"
                  className="hover"
                />
                <div className="shadow"></div>
              </a>

              <a
                href={env.ELEMENT_RELIC_COLLECTION_URL || "#"}
                target="_blank"
                rel="noreferrer noopener"
              >
                <img
                  src={imgLinkElementRelic}
                  alt="element relic"
                  className="logo"
                />
                <img
                  src={imgLinkElementRelicHover}
                  alt="element relic hover"
                  className="hover"
                />
                <div className="shadow"></div>
              </a>

              <a href={DISCORD} target="_blank" rel="noreferrer noopener">
                <img src={imgLink3} alt="" className="logo" />
                <img src={imgLink3Hover} alt="" className="hover" />
                <div className="shadow"></div>
              </a>

              <a href={TWITTER} target="_blank" rel="noreferrer noopener">
                <img src={imgLink4} alt="" className="logo" />
                <img src={imgLink4Hover} alt="" className="hover" />
                <div className="shadow"></div>
              </a>

              <a
                href={i18n.language === "en" ? WHITE_PAPER : ZH_TW_WHITE_PAPER}
                target="_blank"
                rel="noreferrer noopener"
              >
                <img src={imgLink5} alt="" className="logo" />
                <img src={imgLink5Hover} alt="" className="hover" />
                <div className="shadow"></div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
