import { MetaMask, Web3Wallet, ChainParameter } from "web3wallet/dist/web3wallet";

const Wallet = {
    create(chainId, rpcUrl, chainName, nativeCurrency, blockExplorerUrls) {
        const sender = new MetaMask();
        const web3Wallet = new Web3Wallet(sender, sender);
        const chainParameter = new ChainParameter();
        chainParameter.chainId = chainId;
        chainParameter.rpcUrls = [rpcUrl];
        chainParameter.chainName = chainName;
        chainParameter.nativeCurrency = nativeCurrency;
        chainParameter.blockExplorerUrls = blockExplorerUrls;
        web3Wallet.setChainParameter(chainParameter);
        return {
            web3Wallet,
            sender,
        };
    },
};

export default Wallet;