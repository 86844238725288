import { useState } from 'react';
import { useTranslation } from "react-i18next";

const FQ = () => {
  const { t } = useTranslation();
  const [showIndex, setShowIndex] = useState();

  const changeShowIndex = (index) => {
    if (index === showIndex) {
      setShowIndex(null);
    } else {
      setShowIndex(index);
    }
  };

	return (
    <section id='FQsection' className='FQ section'>
			<div className='section-content'>
        <div className='t1'>F&amp;Q</div>
        <div className={showIndex === 1 ? 'active' : ''} onClick={() => changeShowIndex(1)}>
          <div className="t2">{t('FQSection.Q_What’s LLAMA LAND GameFi?')}</div>
          <p>{t('FQSection.A_What’s LLAMA LAND GameFi?')}</p>
        </div>
        <div className={showIndex === 2 ? 'active' : ''} onClick={() => changeShowIndex(2)}>
          <div className="t2">{t('FQSection.Q_What chain is LLAMA LAND using?')}</div>
          <p>{t('FQSection.A_What chain is LLAMA LAND using?')}</p>
        </div>
        <div className={showIndex === 3 ? 'active' : ''} onClick={() => changeShowIndex(3)}>
          <div className="t2">{t('FQSection.Q_How many Llamas will there be?')}</div>
          <p>{t('FQSection.A_How many Llamas will there be?')}</p>
        </div>
        <div className={showIndex === 4 ? 'active' : ''} onClick={() => changeShowIndex(4)}>
          <div className="t2">{t('FQSection.Q_How is Each Llama Unique?')}</div>
          <p>{t('FQSection.A_How is Each Llama Unique?')}</p>
        </div>
        <div className={showIndex === 6 ? 'active' : ''} onClick={() => changeShowIndex(6)}>
          <div className="t2">{t('FQSection.Q_What is the Llama mint price? How many can I mint at once?')}</div>
          <p>{t('FQSection.A_What is the Llama mint price? How many can I mint at once?')}</p>
        </div>
      </div>
    </section>
  );
};

export default FQ;