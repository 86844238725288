import React from "react";
import useAlert from "../../store/useAlert";
import imgClose from "./assets/close.svg";
import { useTranslation } from "react-i18next";

const Alert = () => {
  const { t } = useTranslation();
  const [alert, setAlert] = useAlert();
  const { show, type, title, message, callback, showCloseBtn } = alert;

  const closeAlert = () => {
    setAlert({
      ...alert,
      show: false,
      callback: () => {},
    });

    if (type === "question" || !callback) return;
    callback();
  };

  return (
    <div
      id="Alert"
      className={show ? "active" : ""}
      onClick={() => {
        if (showCloseBtn) {
          closeAlert();
        }
      }}
    >
      <div
        className={`center-wrap ${type ? type : ""}`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="content">
          <div className={`icon ${type ? type : ""}`}></div>
          <div className="detail">
            <div className={`title ${type ? type : ""}`}>
              {title ? title : ""}
            </div>
            <div className="message">{message}</div>
          </div>
        </div>

        {(type !== "info" || showCloseBtn) && (
          <div className="close" onClick={closeAlert}>
            <img src={imgClose} alt="close popup" />
          </div>
        )}

        {type === "question" && (
          <div className="buttons">
            <button
              className="button-confirm"
              onClick={(e) => {
                e.stopPropagation();
                setAlert({
                  ...alert,
                  show: false,
                  callback: () => {},
                });

                callback();
              }}
            >
              {t("Confirm")}
            </button>
            <button
              className="button-cancel"
              onClick={(e) => {
                e.stopPropagation();
                setAlert({
                  ...alert,
                  show: false,
                  callback: () => {},
                });
              }}
            >
              {t("Cancel")}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Alert;
