import React, { useRef, useMemo, useState, useEffect } from "react";
import imgTitle from "./assets/title.svg";
import closeIcon from "./assets/X.png";
import useLoading from "../../store/useLoading";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { EffectCreative, Pagination, Navigation } from "swiper";
import onScrollHandler from "../../utils/scrollTo";

// Banner
import announcementBanner1 from "./assets/announcement_banner_1.png";
import announcementBanner2 from "./assets/announcement_banner_2.png";
import announcementTitleNews from "./assets/announcement_title_news.png";
import announcementTitleNewsMobile from "./assets/announcement_title_news_m.png";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const Landing = (props) => {
  const { t, i18n } = useTranslation();
  const swiper = useSwiper();
  const windowScrollY = useRef(window.screenY); // 紀錄開啟彈窗前的視窗頂部位置
  const loading = useLoading()[0];
  const { viewportType, haveSeenAnnouncement, setHaveSeenAnnouncement } = props;
  const [, setNowActiveAnnouncement] = useState(0);

  const seenAnnouncementHandler = () => {
    window.localStorage.setItem("haveSeenAnnouncement", "true");
    setHaveSeenAnnouncement(true);

    // 關閉公告後，將視窗滾動至開啟公告前的位置
    window.scrollTo({
      top: windowScrollY.current,
      behavior: "smooth",
    });
  };

  // 要展示的 Announcement
  const renderAnnouncement = useMemo(() => {
    return [
      {
        id: 0,
        image: announcementBanner1,
        url: null,
        onClick: () => {
          setHaveSeenAnnouncement((draft) => !draft);
          onScrollHandler(viewportType, "MINT");
        },
        title: "LLAMAS TOGETHER STRONG ",
        content:
          "Limited time Llama NFT offer, now is your best chance to join our Llama family!",
        sort: 0,
      },
      {
        id: 1,
        image: announcementBanner2,
        url: "https://discord.com/channels/1016902004083269692/1156075287444996147/1156096641359675432",
        title: "Galxe exclusive holder campaign!",
        content:
          "3950 USDT prize pool are open for holder to claim! Do not miss it!",
        sort: 2,
      },
    ].sort((a, b) => a.sort - b.sort);
  }, [setHaveSeenAnnouncement, viewportType]);

  useEffect(() => {
    if (!swiper) return;
    // 重新渲染 Swiper
    swiper.update();
  }, [swiper, viewportType]);

  return (
    <section id="LANDINGsection" className="Landing section">
      <div className="carousel">
        <div className="pointer-left">
          <div className="text-wrap text-wrap1">
            <div className="number">5</div>
            <div
              className={i18n.language === "zh-Tw" ? "text text-zh" : "text"}
            >
              {t("NFTSection.Type of")}
              {i18n.language === "en" && <br />}
              {t("NFTSection.mouths")}
            </div>
          </div>

          <div className="text-wrap text-wrap2">
            <div className="number">4</div>
            <div
              className={i18n.language === "zh-Tw" ? "text text-zh" : "text"}
            >
              {t("NFTSection.Races")}
            </div>
          </div>
        </div>

        <div className="pointer-right">
          <div className="text-wrap text-wrap3">
            <div className="number">55</div>
            <div
              className={i18n.language === "zh-Tw" ? "text text-zh" : "text"}
            >
              {t("NFTSection.Hat")} / {t("NFTSection.Clothes")} /<br />
              {t("NFTSection.Belts")} / {t("NFTSection.Hand Items")}
              <br />
              {t("NFTSection.Backpacks")}
            </div>
          </div>

          <div className="text-wrap text-wrap4">
            <div className="number">9</div>
            <div
              className={i18n.language === "zh-Tw" ? "text text-zh" : "text"}
            >
              {t("NFTSection.Type of")}
              <br />
              {t("NFTSection.eyes")}
            </div>
          </div>
        </div>
      </div>

      <div className="title">
        <img src={imgTitle} alt="RISE AND JOIN THE LLAMA ARMY!" />
      </div>

      <div className="ticker"></div>

      {renderAnnouncement.length > 0 && (
        <div
          className={
            haveSeenAnnouncement ? "announcement" : "announcement fullScreen"
          }
        >
          <div className="modal">
            <div className="modal__header">
              <div className="title">
                <img
                  src={
                    viewportType === "isMobile"
                      ? announcementTitleNewsMobile
                      : announcementTitleNews
                  }
                  alt="ANNOUNCEMENT NEWS"
                />
              </div>
              <div className="close" onClick={() => seenAnnouncementHandler()}>
                <img src={closeIcon} alt="X" />
              </div>
            </div>
            {!loading && (
              <>
                {haveSeenAnnouncement ? (
                  <img
                    className="smallAnnouncementBanner"
                    src={renderAnnouncement[0].image || ""}
                    alt={
                      renderAnnouncement[0].title || "small announcement banner"
                    }
                    onClick={() => {
                      // 記錄滑動位置
                      windowScrollY.current = window.scrollY;

                      // 如果目前 URL 有 hash，則先移除，防止畫面卡住
                      const { hash } = window.location;

                      if (hash) {
                        hash.replace("#", "");
                      }
                      // 視窗移動到最上方
                      onScrollHandler(viewportType, "LANDING");
                      setHaveSeenAnnouncement(false);
                    }}
                  />
                ) : (
                  <>
                    {viewportType === "isMobile" ? (
                      <Swiper
                        effect="creative"
                        creativeEffect={{
                          prev: {
                            shadow: true,
                            translate: ["-34%", 0, -600],
                          },
                          next: {
                            shadow: true,
                            translate: ["34%", 0, -600],
                          },
                        }}
                        modules={[EffectCreative, Pagination, Navigation]}
                        slidesPerView="1"
                        pagination={{
                          clickable: true,
                        }}
                        navigation={true}
                        onSlideChange={(e) => {
                          setNowActiveAnnouncement(e.activeIndex);
                        }}
                      >
                        {renderAnnouncement.map((announcement) => (
                          <SwiperSlide key={announcement.id}>
                            <img
                              className="announcement__banner"
                              src={announcement.image}
                              alt={announcement.title}
                              onClick={() => {
                                if (announcement.url) {
                                  window.open(announcement.url, "_blank");
                                }
                                if (announcement.onClick) {
                                  announcement.onClick();
                                }
                              }}
                            />
                            <div className="announcement__content">
                              <h2>{announcement.title}</h2>
                              <p>{announcement.content}</p>
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    ) : (
                      <Swiper
                        effect="creative"
                        creativeEffect={{
                          prev: {
                            shadow: true,
                            translate: ["-60%", 0, -400],
                          },
                          next: {
                            shadow: true,
                            translate: ["60%", 0, -400],
                          },
                        }}
                        modules={[EffectCreative, Pagination, Navigation]}
                        centeredSlides={true}
                        slidesPerView="2"
                        pagination={{
                          clickable: true,
                        }}
                        navigation={true}
                        onSlideChange={(e) => {
                          setNowActiveAnnouncement(e.activeIndex);
                        }}
                      >
                        {renderAnnouncement.map((announcement) => (
                          <SwiperSlide key={announcement.id}>
                            <img
                              className="announcement__banner"
                              src={announcement.image}
                              alt={announcement.title}
                              onClick={() => {
                                if (announcement.url) {
                                  window.open(announcement.url, "_blank");
                                }
                                if (announcement.onClick) {
                                  announcement.onClick();
                                }
                              }}
                            />
                            <div className="announcement__content">
                              <h2>{announcement.title}</h2>
                              <p>{announcement.content}</p>
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </section>
  );
};

export default Landing;
